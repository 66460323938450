import { FeatureCollection, Point } from 'geojson'
import StationOnMapImage from 'images/station_on_map.png'
import { useEffect, useMemo } from 'react'
import { Layer, Source, SymbolLayer } from 'react-map-gl'
import { IStation } from 'utils/interfaces'
import { zoomMapTo } from 'utils/mapUtils'

const layerStationStyle: SymbolLayer = {
  id: 'station-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'station',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.6, 22, 3.2],
    'icon-rotate': ['get', 'yaw'],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
}

const AllStationsOnMap = ({
  map,
  stations,
  onStationClicked,
}: {
  map: mapboxgl.Map | undefined
  stations: IStation[]
  onStationClicked: (id?: number) => void
}) => {
  const stationsOnMap: FeatureCollection<Point> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: stations
        .filter(station => station.point)
        .map(station => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: station.point?.coordinates || [],
            },
            properties: {
              opacity: 1,
              id: station.id,
              yaw: 0,
            },
          }
        }),
    }
  }, [stations])

  useEffect(() => {
    loadStationImage(map)
    map?.on('style.load', () => loadStationImage(map))
    zoomMapTo(map, stationsOnMap.features)
  }, [map, stationsOnMap.features])

  const onStationClickedOnMap = (e: any) => {
    if (e.features[0]) {
      onStationClicked(e.features[0].properties.id)
    } else {
      console.warn('Here should be station, but got nothing')
    }
  }

  const onNothingClicked = (e: any) => {
    // setHighlightedRobot(e?.features && e?.features[0].properties?.id_on_map)'
    onStationClicked()
  }

  useEffect(() => {
    map?.on('click', onNothingClicked)
    map?.on('click', 'station-image-style', onStationClickedOnMap)
    return () => {
      map?.off('click', onNothingClicked)
      map?.off('click', 'station-image-style', onStationClickedOnMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const loadStationImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('station')) {
      return
    }
    map?.loadImage(StationOnMapImage, (error, image) => {
      if (error || !image) {
        return
      }
      if (map?.hasImage('station')) {
        return
      }
      map?.addImage('station', image)
    })
  }
  return (
    <>
      {stationsOnMap && (
        <Source id="station" type="geojson" data={stationsOnMap}>
          <Layer {...layerStationStyle} />
        </Source>
      )}
    </>
  )
}

export default AllStationsOnMap
