import clsx from 'clsx'
import { format } from 'date-fns'
import { ReactComponent as NoConnectionSvg } from 'images/icons/signal_wifi_off.svg'
import { ReactComponent as ConnectionSvg } from 'images/icons/signal_wifi_on.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectLots, selectStation } from 'store/selectors/station'
import { lotTypeMap}  from 'utils/lotInterfaces'
import { IRobotInfo } from 'utils/interfaces'

import style from '../StationDetails.module.css'
import { ILot } from 'utils/interfaces'

interface StationInfoProps {
  robots: IRobotInfo[];
}

const StationInfo: React.FC<StationInfoProps> = ({ robots }) => {
  const { t } = useTranslation()
  const station = useSelector(selectStation)
  const isOnline = station.online === true
  const lots = useSelector(selectLots).filter((lot) => lot.robot_station === station.id)

  const getLastActionTimestamp = (lot: ILot) => {
    if (!lot || !lot.state_history || lot.state_history.length === 0) {
      return null;
    }
    return lot.state_history[0].timestamp;
  };

  return (
    <div className={style.root}>
      <div className={style.tableRow}>
        <div>{isOnline ? <ConnectionSvg /> : <NoConnectionSvg />}</div>
        <div className={style.textAlignLeft}>Status</div>
        <div className={clsx(style.chip, style.centered, style.locked, isOnline ? style.online : '')}>
          {`${isOnline ? 'Online' : 'Offline'}`}
        </div>
        <div>
          <div className={style.percents}>
            {station.updatedAt? format(new Date(station.updatedAt), 'dd LLLL yyyy') : '-'}          
          </div>
          <div className={style.percents}>
            {station.updatedAt? format(new Date(station.updatedAt), 'HH:mm:ss') : '-'}          
          </div>
        </div>
      </div>
      {lots.map((lot) => {
        const lastActionTimestamp = getLastActionTimestamp(lot);
        return (
        <div key={lot.id} className={style.tableRow}>
          <div></div>
          <div className={style.textAlignLeft}>Lot</div>
          <div className={clsx(style.chip, style.centered, style.locked)}>{lotTypeMap[lot.type]}</div>
          <div>
            <div className={style.percents}>
              {lastActionTimestamp ? format(new Date(lastActionTimestamp), 'dd LLLL yyyy') : '-'}
            </div>
            <div className={style.percents}>
              {lastActionTimestamp ? format(new Date(lastActionTimestamp), 'HH:mm:ss') : '-'}
            </div>
          </div>
        </div>
        );
      })}
      <div className={style.tableRow}>
        <div></div>
        <div className={style.textAlignLeft}>Robots:</div>
        <span className={style.tableCol}>
          {robots.filter(robot => robot.stationId === station.id)
           .map(robot => robot.name)
           .join(', \n')}
        </span>
        <div></div>
      </div>
    </div>
  )
}

export default StationInfo
