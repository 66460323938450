import { Point } from 'geojson'
import {
  MISSION_JOB_STATUSES,
  ROBOT_COMMANDS,
  ROBOT_EQUIPMENT_COMMAND,
  ROBOT_MODES,
  ROBOT_NETWORK_STATUSES,
  ROBOT_STATUSES,
} from 'utils/constants'
import { IStation, IRobotInfo, ILot } from 'utils/interfaces'

import { MainService, MissionJob, RobotRead, SchedulerSettings, MissionJobTypeEnum } from './schema'

const normalizeRobot = (robotRaw: RobotRead): IRobotInfo => {
  const mission_job = robotRaw.missions_jobs.find(job => job.id === robotRaw.last_job_id)
  return {
    id: robotRaw.id,
    serialNumber: robotRaw.serial_number,
    robot_type: robotRaw.robot_type,
    name: robotRaw.name || '',
    description: robotRaw.description || '',
    createdAt: robotRaw.created_at,
    updatedAt: robotRaw.updated_at,
    status: ROBOT_STATUSES.AVAILABLE,
    robotRaw: robotRaw,
    sim_card_number: robotRaw.sim_card_number || '',
    robot_color: robotRaw.robot_color || '',
    activeMode: robotRaw.active_mode as ROBOT_MODES,
    activeCommand: robotRaw.active_command as ROBOT_COMMANDS,
    job: mission_job && {
      id: mission_job.id,
      missionName: mission_job.mission_name,
      missionId: mission_job.mission_id,
      status: mission_job.status as MISSION_JOB_STATUSES,
    },
    networkStatus: (robotRaw.network_status as ROBOT_NETWORK_STATUSES) || ROBOT_NETWORK_STATUSES.OFFLINE,
    stationId: robotRaw.station_id || undefined,
    lastPosition: robotRaw.telemetry_last_point,
    lastOrientation: robotRaw.telemetry_last_orientation as { x: number; y: number; z: number; w: number },
  }
}

export const RobotApi = {
  fetchRobots: async (): Promise<IRobotInfo[]> => {
    const robots = await MainService.mainRobotList()
    if (!robots.results) {
      return []
    }
    const normalizedRobots = robots.results.map(robotRaw => normalizeRobot(robotRaw))
    return normalizedRobots
  },
  createRobot: async (
    name: string,
    description: string,
    serialNumber: number,
    stationId?: number | null,
    sim_card_number?: string | null,
    robotType?: string | number | null,
    robotColor?: string | number | null
  ): Promise<boolean> => {
    const robot = await MainService.mainRobotCreate({
      name,
      description,
      users_ids: [],
      serial_number: serialNumber,
      station_id: stationId,
      sim_card_number: sim_card_number,
      robot_type: robotType,
      robot_color: robotColor 
    })
    if (robot) {
      return true
    }
    return false
  },
  patchRobot: async (
    id: number, 
    name: string, 
    description: string, 
    stationId?: number, 
    sim_card_number?:string | null, 
    robotType?: string | number | null, 
    robotColor?: string | number | null
  ): Promise<boolean> => {
    const robot = await MainService.mainRobotPartialUpdate(id, {
      name,
      description,
      station_id: stationId,
      sim_card_number: sim_card_number,
      robot_type: robotType,
      robot_color: robotColor
    })
    if (robot) {
      return true
    }
    return false
  },
  fetchRobot: async (id: number): Promise<IRobotInfo> => {
    const robotRaw = await MainService.mainRobotRetrieve(id)
    const robot = normalizeRobot(robotRaw)
    return robot
  },
  deleteRobot: async (id: number): Promise<void> => {
    await MainService.mainRobotDestroy(id)
  },
  createJob: async (missionId: number, 
                    robotId: number, 
                    scheduler_settings?: SchedulerSettings,
                    name?: string, 
                    description?: string): Promise<MissionJob> => {
    const task = await MainService.mainMissionJobCreate({
      mission_id: missionId,
      robot_id: robotId,
      scheduler_settings: scheduler_settings ?? null,
      name: name ?? null,
      description: description ?? null,
    })
    return task
  },

  updateTheEntireJob: async (task_id: number, 
                    missionId: number, 
                    robotId: number, 
                    scheduler_settings?: SchedulerSettings,
                    name?: string,
                    status: MISSION_JOB_STATUSES = MISSION_JOB_STATUSES.NEW): Promise<MissionJob> => {
    const task = await MainService.mainMissionJobPartialUpdate(task_id, {
      robot_id: robotId,
      mission_id: missionId,
      name: name,
      scheduler_settings: scheduler_settings,
      status: status
    })
    return task
  },

  updateMissionJobType: async (task_id: number,
                      job_type: MissionJobTypeEnum,
                      status: MISSION_JOB_STATUSES = MISSION_JOB_STATUSES.NEW): Promise<MissionJob> => {
    const task = await MainService.mainMissionJobPartialUpdate(task_id, {
      job_type: job_type,
      status: status
    })
    return task
  },

  uploadJobToRobot: async (jobId: number) => {
    await MainService.mainMissionJobStartCalculationCreate(jobId)
  },
  switchMode: async (id: number, mode: ROBOT_MODES) => {
    await MainService.mainRobotSwitchModeCreate(id, String(mode))
  },
  execCommand: async (id: number, command: ROBOT_COMMANDS) => {
    await MainService.mainRobotExecCommandCreate(id, String(command))
  },
  execEquipmentCommand: async (id: number, command: ROBOT_EQUIPMENT_COMMAND, value: string): Promise<void> => {
    await MainService.mainRobotExecEquipmentCommandCreate(command, id, value)
  },
}
