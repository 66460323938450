import { MainApi } from 'api/MainApi'
import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import Title from 'components/Title/Title'
import isEqual from 'lodash.isequal'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import {
  selectGeoTiffLayer,
  selectMissionInfo,
  selectMissionTempVisualSettings,
  selectMissionVisualSettings,
} from 'store/selectors/mission'
import store from 'store/store'
import { layerThunks } from 'store/thunks/layer'
import { DEFAULT_VIZUALIZATION_SETTINGS, GEOTIFF_LAYER_STATUSES, PROGRESS_TYPES, FILES_LOADING_TYPE } from 'utils/constants'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { MainService } from 'api/schema'

import VisualSettings from './VisualSettings/VisualSettings'
import SelectFileModal from '../../../SelectFileModal/SelectFileModal'
import style from './MissionSettingsModal.module.css'

interface IDialogProps {
  isOpen: boolean
  missionId: number
  missionName: string
  onClose: () => void
}

const MissionSettingsModal = ({ isOpen, missionId, missionName, onClose }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputFile = useRef<HTMLInputElement | null>(null)
  const missionInfo = useSelector(selectMissionInfo)
  const geoTiffLayer = useSelector(selectGeoTiffLayer)
  const visualSettings = useSelector(selectMissionVisualSettings)
  const tempVisualSettings = useSelector(selectMissionTempVisualSettings)

  const [tempLayerName, setTempLayerName] = useState(geoTiffLayer?.layer?.name || 'GeoTIFF layer not added')
  const [nameChanged, setNameChanged] = useState(false)
  const [tempMissionName, setTempMissionName] = useState(missionName)
  const [hasNameError, setNameHasError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [actionInProgress, setActionInProgress] = useState(false)
  const [robotTypes, setRobotTypes] = useState<string[]>([]) 
  const [robotType, setRobotType] = useState(missionInfo?.robot_type || "Sweeper")  
  const [transportSpeed, setTransportSpeed] = useState(missionInfo?.transport_speed || 0.1)  
  const [cleaningSpeed, setCleaningSpeed] = useState(missionInfo?.cleaning_speed || 0.1)  

  const fetchData = async () => {
    try {
      const robots_response = await MainService.mainRobotList()
      const uniqueRobotTypes = Array.from(new Set(robots_response.results? robots_response.results.map(robot => robot.robot_type) : []))
      setRobotTypes(uniqueRobotTypes)
    } catch (error) {
    }
  }

  useEffectOnce(() => {
    fetchData()
  })

  const onMissionNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setNameHasError(true)
    } else {
      setNameHasError(false)
      setTempMissionName(e.target.value)
    }
  }
 
  const onLayerNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value !== '') {
    //   setTempLayerName(e.target.value)
    // }
  }

  const layerMessage = useMemo(() => {
    if (!geoTiffLayer.layer?.status) {
      return t('mission_settings.geotiff_description')
    }
    switch (geoTiffLayer.layer?.status) {
      case GEOTIFF_LAYER_STATUSES.ERROR:
        return t('mission_settings.geotiff_error')
      case GEOTIFF_LAYER_STATUSES.OK:
        return t('mission_settings.geotiff_status_ok')
      case GEOTIFF_LAYER_STATUSES.NEW:
        return t('mission_settings.geotiff_status_new')
      case GEOTIFF_LAYER_STATUSES.PENDING:
        return t('mission_settings.geotiff_status_pending')
    }
  }, [geoTiffLayer.layer?.status, t])

  const onCancelClicked = () => {
    if (!actionInProgress) {
      onClose()
      if (!isEqual(tempVisualSettings, visualSettings)) {
        dispatch(MissionActions.setTempVisualSettings({ ...visualSettings }))
      }
    }
    setRobotType(missionInfo?.robot_type || "Sweeper")
    setRobotTypeOption(missionInfo?.robot_type ? { text: missionInfo?.robot_type, value: missionInfo?.robot_type } : robotTypeOptions[0])

    setTransportSpeed(missionInfo?.transport_speed || 0.1)
    setTransportSpeedOption(missionInfo?.transport_speed ? { text: missionInfo?.transport_speed, value: missionInfo?.transport_speed } : transportSpeedOptions[0])

    setCleaningSpeed(missionInfo?.cleaning_speed || 0.1)
    setCleaningSpeedOption(missionInfo?.cleaning_speed ? { text: missionInfo?.cleaning_speed, value: missionInfo?.cleaning_speed } : cleaningSpeedOptions[0])
  }

  const onSaveClicked = async () => {
    setActionInProgress(true)

    try {
      if (tempMissionName !== missionInfo?.name) {
        const mission = await MainApi.changeMissionName(missionId, tempMissionName)
        if (mission.name)
          dispatch(MissionActions.setMissionName(mission.name))
      }

      if (robotType !== missionInfo?.robot_type) {
        const mission = await MainApi.changeMissionRobotType(missionId, robotType)
        if (mission.robot_type)
          dispatch(MissionActions.setMissionRobotType(mission.robot_type))
      }

      if (transportSpeed !== missionInfo?.transport_speed ||
          cleaningSpeed !== missionInfo?.cleaning_speed) {
        const mission = await MainApi.changeMissionSpeed(missionId, transportSpeed, cleaningSpeed)
        if (mission.transport_speed && mission.cleaning_speed){
          dispatch(MissionActions.setMissionTransportSpeed(mission.transport_speed))
          dispatch(MissionActions.setMissionCleaningSpeed(mission.cleaning_speed))
        }
      }

      if (!isEqual(tempVisualSettings, visualSettings)) {
        const mission = await MainApi.changeMissionVisualSettings(missionId, tempVisualSettings)
        dispatch(
          MissionActions.setVisualSettings({ ...DEFAULT_VIZUALIZATION_SETTINGS, ...mission.visualization_settings })
        )
      }
    } catch (error) {
      console.log(error)
    }

    setActionInProgress(false)
    onClose()
    if (nameChanged) {
      setNameChanged(false)
      window.location.reload();
    }
  }

  const onSelectFileClick = () => {
    inputFile.current?.click()
  }

  const onDeleteLayerClick = () => {
    setTempLayerName('A GeoTIFF layer not added')
    if (geoTiffLayer.layer?.id) {
      store.dispatch(layerThunks.deleteLayer(geoTiffLayer.layer?.id))
    }
  }

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const files = target?.files
    if (files && missionInfo?.id) {
      store.dispatch(layerThunks.createLayer(missionInfo?.id, tempLayerName, files[0]))
      event.target.value = ''
    }
  }

  const onServerFileSelected = () => {
    setShowModal(false)
  }

  const onSelectFromServerClick = () => {
    setShowModal(true)
  }

  const handleSelectFromServer = async (filename: string) => {
    setTempLayerName(filename)
    setNameChanged(true)
    if (missionInfo?.id) { 
      store.dispatch(layerThunks.createLayerFromServer(missionInfo?.id, filename))
    }
  };

  const deletingLayerInProgress = geoTiffLayer.deletingProgress === PROGRESS_TYPES.WORK
  const uploadingLayerInProgress = geoTiffLayer.uploadingProgress === PROGRESS_TYPES.WORK
  const isSaveButtonDisabled =
    deletingLayerInProgress || 
    geoTiffLayer.layer?.status == GEOTIFF_LAYER_STATUSES.NEW ||
    geoTiffLayer.layer?.status == GEOTIFF_LAYER_STATUSES.PENDING ||
    (tempMissionName === '' && isEqual(visualSettings, tempVisualSettings))

  const robotTypeOptions: IOption[] = Object.values(robotTypes).filter((value) => typeof value === 'string').map(type => ({
    text: type,
    value: type,
  }));

  const [robotTypeOption, setRobotTypeOption] = useState(
    robotType ? { text: robotType, value: robotType } : robotTypeOptions[0]
  )

  const onTypeSelected = (e: IOption) => {
    setRobotTypeOption(e);
    setRobotType(e.value as string);
  };

  const transportSpeedOptions: IOption[] = Array.from({ length: 19 }, (_, i) => {
    const speed = (0.2 + i * 0.1).toFixed(1); 
    return {
      text: speed,
      value: parseFloat(speed),
    };
  });

  const [transportSpeedOption, setTransportSpeedOption] = useState(
    transportSpeed ? { text: transportSpeed, value: transportSpeed } : transportSpeedOptions[0]
  )

  const onTransportSpeedChanged = (e: IOption) => {
    setTransportSpeedOption(e)
    setTransportSpeed(e.value as number)
  }

  const cleaningSpeedOptions: IOption[] = Array.from({ length: 14 }, (_, i) => {
    const speed = (0.2 + i * 0.1).toFixed(1); 
    return {
      text: speed,
      value: parseFloat(speed),
    };
  });
  
  const [cleaningSpeedOption, setCleaningSpeedOption] = useState(
    cleaningSpeed ? { text: cleaningSpeed, value: cleaningSpeed } : transportSpeedOptions[0]
  )

  const onCleaningSpeedChanged = (e: IOption) => {
    setCleaningSpeedOption(e)
    setCleaningSpeed(e.value as number)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text={t('mission_settings.title')} className={style.title} />
        </div>
        <div className={style.content}>
          <div className={style.row}>
            <TextFieldDescribed
              className={style.textfield}
              description={t('mission_settings.name_description')}
              error={t('mission_settings.name_error')}
              placeholder={t('mission_settings.name_placeholder')}
              hasError={hasNameError}
              defaultValue={missionName}
              onChange={onMissionNameChanged}
            />
          </div>
          {/* <div className={style.contentColumns}>
            <TextFieldDescribed
              className={style.rightMargin}
              description={t('mission_settings.buffer_description')}
              rightLabel={t('mission_settings.meters')}
              placeholder={t('mission_settings.name_placeholder')}
              defaultValue="25"
              onChange={() => {}}
            />
            <TextFieldDescribed
              description={t('mission_settings.start_point_max_distance')}
              rightLabel={t('mission_settings.meters')}
              placeholder={t('mission_settings.name_placeholder')}
              defaultValue="33"
              onChange={() => {}}
            />
          </div> */}
          <div className={style.row}>
            <Dropdown
              className={style.textfieldFullWidth}
              currentOption={robotTypeOption}
              options={robotTypeOptions}
              onClickOption={onTypeSelected}
              description={t('mission_settings.robot_type')}
            />
          </div>
          <div className={style.row}>
            <Dropdown
              className={style.textfield}
              currentOption={transportSpeedOption}
              options={transportSpeedOptions}
              onClickOption={onTransportSpeedChanged}
              description={t('mission_settings.transport_speed')}
            />
            <Dropdown
              className={style.textfield}
              currentOption={cleaningSpeedOption}
              options={cleaningSpeedOptions}
              onClickOption={onCleaningSpeedChanged}
              description={t('mission_settings.cleaning_speed')}
            />
          </div>
          <div className={style.selectFileBlock}>
            <TextFieldDescribed
              className={style.textfieldFullWidth}
              description={t('mission_settings.geotiff_map_layer')}
              placeholder={t('mission_settings.name_placeholder')}
              value={tempLayerName}
              disabled={true}
              onChange={onLayerNameChanged}
            />
            {/* {!geoTiffLayer.layer && (
              <Button.Outlined
                className={style.btnSelect}
                onClick={onSelectFileClick}
                loading={uploadingLayerInProgress}
                disabled={uploadingLayerInProgress}
              >
                {uploadingLayerInProgress ? t('mission_settings.uploading_file') : t('mission_settings.select_file')}
              </Button.Outlined>
            )} */}
            {geoTiffLayer.layer && (
              <Button.Outlined
                className={style.btnSelect}
                onClick={onDeleteLayerClick}
                loading={deletingLayerInProgress}
                disabled={deletingLayerInProgress}
              >
                {deletingLayerInProgress ? t('mission_settings.deleting_layer') : t('mission_settings.delete_layer')}
              </Button.Outlined>
            )}
            {!geoTiffLayer.layer && (
              <Button.Outlined
                className={style.btnSelect}
                onClick={onSelectFromServerClick}
                loading={uploadingLayerInProgress}
                disabled={uploadingLayerInProgress}
              >
                Select from server
              </Button.Outlined>
            )}
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              accept=" image/tif, .tif"
              multiple={false}
              onChange={onFileSelected}
            />
            <SelectFileModal
              isOpen={showModal}
              mode={FILES_LOADING_TYPE.MAP}
              onClose={() => setShowModal(false)}
              onConfirmClick={handleSelectFromServer}
            />
          </div>
          <div
            className={
              geoTiffLayer.layer?.status === GEOTIFF_LAYER_STATUSES.ERROR
                ? clsx(style.geotiffDescription, style.error)
                : style.geotiffDescription
            }
          >
            {layerMessage}
          </div>
          <VisualSettings />
        </div>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked} disabled={actionInProgress}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            disabled={isSaveButtonDisabled || actionInProgress}
            loading={actionInProgress}
            className={style.btn}
            onClick={onSaveClicked}
          >
            {t('mission_settings.label_save')}
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default MissionSettingsModal
