import StationsOnMap from 'components/StationsOnMap/StationsOnMap'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { Point } from 'geojson'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IStation, IMapboxElement } from 'utils/interfaces'

import style from '../AddStationModal.module.css'

const StationView = ({
  station,
  onNameChanged,
  onStationSNChanged,
  onPointChanged,
}: {
  station: IStation
  onNameChanged: (name: string) => void
  onStationSNChanged: (name: number) => void
  onPointChanged: (point: Point) => void
}) => {
  const { t } = useTranslation()
  const mapRef = useRef<IMapboxElement>(null)
  const [mapInited, setMapInited] = useState(false)

  const onMapInited = useCallback(() => {
    setMapInited(true)
    if (station.point) {
      mapRef.current
        ?.getMap()
        ?.flyTo({ animate: true, duration: 100, center: [station.point.coordinates[0], station.point.coordinates[1]] })
    }
  }, [station.point])

  useEffect(() => {
    if (station.point) {
      mapRef.current
        ?.getMap()
        ?.flyTo({ animate: true, duration: 100, center: [station.point.coordinates[0], station.point.coordinates[1]] })
    }
  }, [station.point])

  return (
    <>
      <div className={style.rowAlignEnd}>
        <TextFieldDescribed
          className={style.textfield}
          description={'Station name'}
          value={station.name}
          placeholder={'Input station name'}
          onChange={e => onNameChanged(e.target.value)}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={'Station ID'}
          value={station.station_id !== 0 ? String(station.station_id) : ''}
          placeholder={'Input station ID or Serial Number'}
          onChange={e => onStationSNChanged(parseInt(e.target.value, 10) || 0)}
          type="number"
        />
      </div>
      <div className={style.map}>
        <MapboxCommon disableRuler={true} ref={mapRef} onMapInited={onMapInited}>
          {mapInited && (
            <StationsOnMap
              map={mapRef.current?.getMap()}
              stations={station.point ? [station.point] : []}
              onPointChanged={onPointChanged}
            />
          )}
        </MapboxCommon>
      </div>
    </>
  )
}

export default StationView
