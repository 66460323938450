import { MainService, RobotRead } from 'api/schema'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import Loader from 'components/Loader/Loader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PROGRESS_TYPES } from 'utils/constants'
import Typography from '@mui/material/Typography';

import RobotsList from './RobotsList/RobotsList'

import style from './SelectRobotModal.module.css'

interface IDialogProps {
  selectedRobotId?: number
  isOpen: boolean
  robotType: string | undefined
  onConfirmClick: (robot: RobotRead | undefined) => void
  onClose: () => void
}

const SelectRobotModal = ({ isOpen, robotType, onConfirmClick, onClose, selectedRobotId }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [robots, setRobots] = useState<RobotRead[]>([])
  const [selectedRobot, setSelectedRobot] = useState<RobotRead | undefined>(undefined)

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const response = await MainService.mainRobotList()
      // console.log("mainRobotList response:", response.results)
      setRobots(response.results 
                                ? robotType ? response.results.filter(robot => robot.robot_type === robotType) : response.results 
                                : [])
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <ConfirmDialog
      isOpen={isOpen}
      className={style.root}
      onClose={onClose}
      onConfirmClick={() => onConfirmClick(selectedRobot)}
      title={"Select Robot"}
      confirmText={"Select"}
      confirmButtonDisabled={!selectedRobot}
    >
      <div className={style.content}>
        {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
        {loadingProgress === PROGRESS_TYPES.SUCCESS && robots.length > 0 && (
          <RobotsList
            robots={robots.sort((a, b) => a.serial_number - b.serial_number)}
            onSelect={robot => setSelectedRobot(robot)}
            selectedRobot={selectedRobot}
          />
        )}
        {loadingProgress === PROGRESS_TYPES.SUCCESS && robots.length === 0 && (
          <Typography variant="body1" align="center">
            {t('robot.modal_robots_empty')}
          </Typography>
        )}
      </div>
    </ConfirmDialog>
  )
}

export default SelectRobotModal
