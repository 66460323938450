import { createSlice } from '@reduxjs/toolkit'
import { IStation, IStationState, ILot } from 'utils/interfaces'
import { PROGRESS_TYPES } from 'utils/constants'

export const initialState: IStationState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  station: {
    id: 0,
    name: '',
    station_id: 0,
    point: undefined,
    createdAt: '',
    updatedAt: '',
    online: false,
  },
  lots: [],
}

export const stationSlice = createSlice({
  name: 'station',
  initialState: initialState,
  reducers: {
    setLoadingProgress: (state: IStationState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setStation: (state: IStationState, action: { payload: IStation }) => {
      state.station = action.payload
    },
    setLots: (state: IStationState, action: { payload: Array<ILot> }) => {
      state.lots = action.payload
    },
    resetStation: () => {
      return initialState
    },
  },
})

export const StationActions = stationSlice.actions

export default stationSlice.reducer
