import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import RadioButton from 'components/RadioButton/RadioButton'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { useCallback, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectMissionTempZone, selectSavingZoneProgress } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { MISSION_ZONE_TYPES, PROGRESS_TYPES, FILES_LOADING_TYPE } from 'utils/constants'
import { IZone } from 'utils/interfaces'
import SelectFileModal from '../../../../SelectFileModal/SelectFileModal'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'

import LaunchPointSettings from './LaunchPointSettings/LaunchPointSettings'

import style from './ZoneDetails.module.css'

const ZoneDetail = ({
  onSaveClicked,
  zone,
}: {
  onSaveClicked: (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => void
  zone?: IZone
}) => {
  const { t } = useTranslation()
  const [selectedZoneType, setSelectedZoneType] = useState(zone?.properties?.zone_type || MISSION_ZONE_TYPES.CLEANING)
  const [name, setName] = useState(zone?.properties?.name || 'New Zone')
  const [description, setDescription] = useState(zone?.properties?.description || '')
  const tempZone = useSelector(selectMissionTempZone)
  const savingProgress = useSelector(selectSavingZoneProgress)
  const [tempRouteName, setTempRouteName] = useState('')
  const [showModal, setShowModal] = useState(false)
  const changeZoneType = useCallback((zoneType: MISSION_ZONE_TYPES) => {
    setSelectedZoneType(zoneType)
  }, [])
  const inputFile = useRef<HTMLInputElement | null>(null)

  const onSave = () => {
    onSaveClicked(name, description, selectedZoneType)
  }

  const onCancelClicked = () => {
    store.dispatch(missionThunks.cancelEditingZone())
  }

  const savingProgressInWork = useMemo(() => {
    return savingProgress === PROGRESS_TYPES.WORK
  }, [savingProgress])

  const onRouteNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
  }

  const onRouteSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
  }

  const onSelectRecordedRouteClick = () => {
    setShowModal(true)
  }

  const onClearZoneClick = () => {
    store.dispatch(missionThunks.clearTempZone())
  }

  const handleSelectRecordedRoute = async (filename: string) => {
    setTempRouteName(filename)
    store.dispatch(missionThunks.getPolygonFromFile(filename, zone))
  };
  

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className="text-14-400">{t('zone_details.select_type')}</div>
        <div className={style.radioButtons}>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.CLEANING}
              label={t('zone_details.type_cleaning')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.CLEANING)}
              disabled={!!zone}
            />
          </div>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.RESTRICT}
              label={t('zone_details.type_restrict')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.RESTRICT)}
              disabled={!!zone}
            />
          </div>
        </div>
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_name')}
          defaultValue={name}
          placeholder={t('zone_details.zone_name_placeholder')}
          onChange={e => setName(e.target.value)}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_description')}
          defaultValue={description}
          placeholder={t('zone_details.zone_description_placeholder')}
          onChange={e => setDescription(e.target.value)}
        />
        {selectedZoneType === MISSION_ZONE_TYPES.CLEANING && (
          <div>
            <div className={clsx('text-14-400', style.subtitle)}>{t('zone_details.start_point')}</div>
            <LaunchPointSettings />
          </div>
        )}
        <div className={style.selectFileBlock}>
          <TextFieldDescribed
            className={style.fileSelectedField}
            description={t('zone_details.select_recorded_route')}
            placeholder={t('zone_details.recorded_route_not_selected')}
            value={tempRouteName}
            disabled={true}
            onChange={onRouteNameChanged}
          />
          <Button.Outlined
            className={style.buttonSmall}
            onClick={onClearZoneClick}
          >
            <RemoveSvg className={style.removeIcon}/>
          </Button.Outlined>
          <Button.Outlined
            className={style.button}
            onClick={onSelectRecordedRouteClick}
          >
            Select from server
          </Button.Outlined>
          <input
            type="Recorded route"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            accept=".json"
            multiple={false}
            onChange={onRouteSelected}
          />
          <SelectFileModal
            isOpen={showModal}
            mode={FILES_LOADING_TYPE.ROUTE}
            onClose={() => setShowModal(false)}
            onConfirmClick={handleSelectRecordedRoute}
          />
        </div>
      </div>
      <div className={style.buttons}>
        <Button.Outlined className={style.button} onClick={onCancelClicked} disabled={savingProgressInWork}>
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={style.button}
          onClick={onSave}
          disabled={(!zone && !tempZone) || savingProgressInWork}
          loading={savingProgressInWork}
        >
          {t('common.save')}
        </Button.Standart>
      </div>
    </div>
  )
}

export default ZoneDetail
