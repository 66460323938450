import { Button } from 'components/Button/Button'
import Title from 'components/Title/Title'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import style from './AddRobotModal.module.css'

interface IDialogProps {
  isOpen: boolean
  onClose: () => void
}

const AddRobotModal = ({ isOpen, onClose }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onCancelClicked = () => {
      onClose()
  }

  const onAddClicked = () => {
      onClose()
      navigate(`${PATH.ROBOT_LIST}`)
  }
 
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text="Add a robot first" className={style.title} />
        </div>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            className={style.btn}
            onClick={onAddClicked}
          >
            Create a robot
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default AddRobotModal
