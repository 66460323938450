import { MainApi } from 'api/MainApi'
import { Button } from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Title from 'components/Title/Title'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'
import { MissionJobRead, MainService } from 'api/schema'

import MissionTable from './MissionTable/MissionTable'
import AddMissionModal from './AddMissionModal/AddMissionModal'
import AddRobotModal from './AddRobotModal/AddRobotModal'

import style from './MissionList.module.css'

const MissionList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [creatingMissionProgress, setCreatingMissionProgress] = useState(PROGRESS_TYPES.IDLE)
  const [missions, setMissions] = useState<IMissionInfo[]>([])
  const [tasks, setTasks] = useState<MissionJobRead[]>([])
  const [robotTypes, setRobotTypes] = useState<string[]>([]) 
  const [isAddMissionModalOpen, setIsAddMissionModalOpen] = useState(false)
  const [isAddRobotModalOpen, setIsAddRobotModalOpen] = useState(false)

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK) 
      const missions = await MainApi.fetchMissions()
      setMissions(missions)
      const jobs_response = await MainService.mainMissionJobList()
      setTasks(jobs_response.results ?? [])
      const robots_response = await MainService.mainRobotList()
      const uniqueRobotTypes = Array.from(new Set(robots_response.results? robots_response.results.map(robot => robot.robot_type) : []))
      setRobotTypes(uniqueRobotTypes)
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  useEffectOnce(() => {
    fetchData()
  })

  const onCloseClicked = () => {
    setIsAddMissionModalOpen(false)
    setIsAddRobotModalOpen(false)
  }

  const onSelectClicked = (robotType: string) => {
    createNewMission(robotType)
    setIsAddMissionModalOpen(false)
  }

  const createButtonPressed = () => {
    if (robotTypes.length === 0) {
      setIsAddRobotModalOpen(true)
    }
    else if (robotTypes.length === 1) {
      createNewMission(robotTypes[0])
    }
    else {
      setIsAddMissionModalOpen(true)
    }
  }

  const createNewMission = async (robotType: string | null = null) => {
    try {
      setCreatingMissionProgress(PROGRESS_TYPES.WORK)
      const mission = await MainApi.createMission('New Mission', robotType ? robotType : robotTypes[0], '')
      setCreatingMissionProgress(PROGRESS_TYPES.SUCCESS)
      navigate(`${PATH.MISSION}${mission.id}`)
    } catch (error) {
      setCreatingMissionProgress(PROGRESS_TYPES.ERROR)
      console.log('Error creating mission: ', error)
    }
  }

  return (
    <>
      {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
      {loadingProgress === PROGRESS_TYPES.ERROR && <div>Error</div>}
      {loadingProgress === PROGRESS_TYPES.SUCCESS && (
        <div className={style.missions}>
          <div className={style.titleBlock}>
            <Title text={t('missions.title')} />{' '}
            <Button.Standart onClick={createButtonPressed} loading={creatingMissionProgress === PROGRESS_TYPES.WORK}>
              Create Mission
            </Button.Standart>
          </div>
          <MissionTable missions={missions.sort((a,b)=> b.id - a.id)} tasks={tasks} onMissionsUpdated={fetchData} />
          <AddMissionModal
            isOpen={isAddMissionModalOpen}
            onClose={onCloseClicked}
            onSelect={onSelectClicked}
            robotTypes={robotTypes}
          />
          <AddRobotModal
            isOpen={isAddRobotModalOpen}
            onClose={onCloseClicked}
          />
        </div>
      )}
    </>
  )
}

export default MissionList
