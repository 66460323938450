import RobotImage from '../RobotImage/RobotImage'
import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import { format } from 'date-fns'
import { ReactComponent as NoConnectionSvg } from 'images/icons/signal_wifi_off.svg'
import { ReactComponent as ConnectionSvg } from 'images/icons/signal_wifi_on.svg'
import cloneDeep from 'lodash.clonedeep'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRobot, selectRobotTelemetry } from 'store/selectors/robot'
import { ROBOT_EQUIPMENT_COMMAND, ROBOT_NETWORK_STATUSES } from 'utils/constants'

import SwitchStyled from './SwitchStyled'

import style from './RobotControl.module.css'

type Status = {
  blocked: boolean
  value: boolean
}

type Statuses = {
  [key in ROBOT_EQUIPMENT_COMMAND]: Status
}

const defaultStatuses: Statuses = {
  // [ROBOT_EQUIPMENT_COMMAND.TRASH]: {
  //   blocked: false,
  //   value: false,
  // },
  [ROBOT_EQUIPMENT_COMMAND.BODY]: {
    blocked: false,
    value: false,
  },
  // [ROBOT_EQUIPMENT_COMMAND.FRONT]: {
  //   blocked: false,
  //   value: false,
  // },
  // [ROBOT_EQUIPMENT_COMMAND.CHARGE]: {
  //   blocked: false,
  //   value: false,
  // },
  [ROBOT_EQUIPMENT_COMMAND.WATER]: {
    blocked: false,
    value: false,
  },
  [ROBOT_EQUIPMENT_COMMAND.LIGHT]: {
    blocked: false,
    value: false,
  },
  [ROBOT_EQUIPMENT_COMMAND.DANGER]: {
    blocked: false,
    value: false,
  },
  [ROBOT_EQUIPMENT_COMMAND.CLEANING_EQUIPMENT]: {
    blocked: false,
    value: false,
  },
}

const RobotControl = () => {
  const robot = useSelector(selectRobot)
  const telemetry = useSelector(selectRobotTelemetry)
  const [statuses, setStatuses] = useState<Statuses>(defaultStatuses)
  const statusesRef = useRef(statuses)
  statusesRef.current = statuses

  useEffect(() => {
    const newStatuses = cloneDeep(statuses)
    // if (!statuses.trash_lock.blocked && telemetry?.locks?.trash !== undefined) {
    //   newStatuses.trash_lock.value = telemetry?.locks?.trash
    // }
    if (!statuses.body_lock.blocked && telemetry?.locks?.body !== undefined) {
      newStatuses.body_lock.value = telemetry?.locks?.body
    }
    // if (!statuses.front_lock.blocked && telemetry?.locks?.front !== undefined) {
    //   newStatuses.front_lock.value = telemetry?.locks?.front
    // }
    // if (!statuses.charge_lock.blocked && telemetry?.locks?.charge !== undefined) {
    //   newStatuses.charge_lock.value = telemetry?.locks?.charge
    // }

    if (!statuses.cleaning_equipment_command.blocked && telemetry?.cleaningEquipmentState !== undefined) {
      newStatuses.cleaning_equipment_command.value = telemetry?.cleaningEquipmentState === 1
    }

    if (!statuses.watering_command.blocked && telemetry?.locks?.watering !== undefined) {
      newStatuses.watering_command.value = telemetry?.locks?.watering
    }
    if (!statuses.light_command.blocked && telemetry?.locks?.light !== undefined) {
      newStatuses.light_command.value = telemetry?.locks?.light
    }
    if (!statuses.danger_button_command.blocked && telemetry?.locks?.danger_button !== undefined) {
      newStatuses.danger_button_command.value = telemetry?.locks?.danger_button
    }
    setStatuses(newStatuses)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telemetry?.locks])

  const isOnline = robot.robotInfo?.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE

  const onLockChanged = async (command: ROBOT_EQUIPMENT_COMMAND, value: boolean) => {
    if (!robot.robotInfo?.id) {
      return
    }
    const newStatuses = cloneDeep(statusesRef.current)
    newStatuses[command].value = value
    newStatuses[command].blocked = true
    setStatuses(newStatuses)
    await RobotApi.execEquipmentCommand(robot.robotInfo?.id, command, value ? '1' : '2')
    setTimeout(function () {
      const newStatuses = cloneDeep(statusesRef.current)
      newStatuses[command].blocked = false
      setStatuses(newStatuses)
    }, 5000)
  }

  return (
    <div className={style.root}>
      {/* <RobotImage /> */}
      {/* <div className={style.tableRow}>
        <div>{isOnline ? <ConnectionSvg /> : <NoConnectionSvg />}</div>
        <div className={style.textAlignLeft}>Status</div>
        <div className={clsx(style.chip, style.centered, style.locked, isOnline ? style.online : '')}>{`${
          isOnline ? 'Online' : 'Offline'
          }`}</div>
        <div className={style.percents}>
          {telemetry?.timestamp ? format(new Date(telemetry.timestamp * 1000), 'dd LLLL yyyy | HH:mm') : '-'}
        </div>
      </div> */}
      {/* <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Trash lock</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={!statuses.trash_lock.value}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.TRASH, !e.target.checked)}
            disabled={statuses.trash_lock.blocked}
          />
        </div>
      </div> */}
      <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Body lock</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={!statuses.body_lock.value}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.BODY, !e.target.checked)}
            disabled={statuses.body_lock.blocked}
          />
        </div>
      </div>
      {/* <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Front lock</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={!statuses.front_lock.value}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.FRONT, !e.target.checked)}
            disabled={statuses.front_lock.blocked}
          />
        </div>
      </div> */}
      {/* <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Charge lock</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={!statuses.charge_lock.value}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.CHARGE, !e.target.checked)}
            disabled={statuses.charge_lock.blocked}
          />
        </div>
      </div> */}
      <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>
          Cleaning equipment (
          <span className={telemetry?.cleaningEquipmentState === 1 ? style.bold : ''}>TRANSPORT</span>/
          <span className={telemetry?.cleaningEquipmentState === 2 ? style.bold : ''}>CLEANING</span>)
        </div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={!statuses.cleaning_equipment_command.value}
            disabled={statuses.cleaning_equipment_command.blocked}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.CLEANING_EQUIPMENT, !e.target.checked)}
          />
        </div>
      </div>
      <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Watering</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={statuses.watering_command.value}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.WATER, e.target.checked)}
            disabled={statuses.watering_command.blocked}
          />
        </div>
      </div>
      <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Light</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={statuses.light_command.value}
            disabled={statuses.light_command.blocked}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.LIGHT, e.target.checked)}
          />
        </div>
      </div>
      <div className={style.tableLockRow}>
        <div className={style.textAlignLeft}>Emergency stop</div>
        <div className={style.alignRight}>
          <SwitchStyled
            checked={statuses.danger_button_command.value}
            disabled={statuses.danger_button_command.blocked}
            onChange={e => onLockChanged(ROBOT_EQUIPMENT_COMMAND.DANGER, e.target.checked)}
          />
        </div>
      </div>
    </div>
  )
}

export default RobotControl
