import { StationApi } from 'api/StationApi'
import { StationActions } from 'store/actions/station'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchStation = (id: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(StationActions.setLoadingProgress(PROGRESS_TYPES.WORK));
      const station = await StationApi.fetchStation(id);
      const lots = await StationApi.fetchLots();
      dispatch(StationActions.setStation(station));
      dispatch(StationActions.setLots(lots));
      dispatch(StationActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS));
    } catch (error) {
      dispatch(StationActions.setLoadingProgress(PROGRESS_TYPES.ERROR));
    }
  }
}

export const stationThunks = {
  fetchStation,
}
