import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { IStation, IRobotInfo } from 'utils/interfaces'
import { RobotTypeEnum, RobotColorEnum } from 'api/schema'

import style from './AddRobotsModal.module.css'

interface IAddRobotModal {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  onStationsChanged: () => void
  className?: string
  robotExisting?: IRobotInfo
  stations: IStation[]
  robots: IRobotInfo[]
}

enum STATION_OPTIONS {
  NONE = 'none',
}

const AddRobotModal: React.FC<IAddRobotModal> = props => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState<string>(props.robotExisting?.name || t('robots.new_robot_name'))
  const [description, setDescription] = useState(props.robotExisting?.description || '')
  const [simNumber, setSimNumber] = useState(props.robotExisting?.sim_card_number || '')
  const getID = () => {
    return props.robotExisting ? props.robotExisting?.serialNumber : Math.max(...props.robots.map(r => r.serialNumber)) + 1
  }
  const [serialId, setSerialId] = useState(getID());
    
  const station = props.stations.find(station => station.id === props.robotExisting?.stationId)

  const [selectedStation, setSelectedStation] = useState<IStation | undefined>(station)

  const selectStationOptions: IOption[] = [
    { text: 'No maintaince station', value: STATION_OPTIONS.NONE },
    ...props.stations.map(station => {
      return { text: station.name, value: station.id }
    }),
  ]

  const robotTypeOptions: IOption[] = Object.values(RobotTypeEnum).filter((value) => typeof value === 'string').map(type => ({
    text: type,
    value: type,
  }));

  const robotColorOptions: IOption[] = Object.values(RobotColorEnum).filter((value) => typeof value === 'string').map(color => ({
    text: color,
    value: color,
  }));

  const [robotType, setRobotType] = useState(props.robotExisting?.robot_type || "Sweeper")
  const [robotColor, setRobotColor] = useState(props.robotExisting?.robot_color || "White")

  const [currentStationOption, setCurrentStationOption] = useState(
    station ? { text: station.name, value: station.id } : selectStationOptions[0]
  )

  const [robotTypeOption, setRobotTypeOption] = useState(
    robotType ? { text: robotType, value: robotType } : robotTypeOptions[0]
  )

  const [robotColorOption, setRobotColorOption] = useState(
    robotColor ? { text: robotColor, value: robotColor } : robotColorOptions[0]
  )

  const onSaveClick = async () => {
    try {
      setSaving(true);
      if (props.robotExisting) {
        await RobotApi.patchRobot(props.robotExisting.id, 
                                  name, 
                                  description, 
                                  selectedStation ? selectedStation.id : undefined, 
                                  simNumber, 
                                  robotType, 
                                  robotColor);
      } else {
        await RobotApi.createRobot(name, 
                                   description, 
                                   serialId, 
                                   selectedStation ? selectedStation.id : undefined, 
                                   simNumber, 
                                   robotType, 
                                   robotColor);
      }
      props.onSave();
    } catch (error) {
      console.log('Error creating robot');
    }
    setSaving(false);
  };

  const onCancelClick = () => {
    setSelectedStation(props.robotExisting ? station : undefined)
    setCurrentStationOption( props.robotExisting && station ? 
                            { text: station.name, value: station.id } : 
                            { text: 'No maintenance station', value: STATION_OPTIONS.NONE })

    setRobotType(props.robotExisting?.robot_type || "Sweeper")
    setRobotTypeOption( props.robotExisting ?
                        { text: props.robotExisting.robot_type, value: props.robotExisting.robot_type } :
                        robotTypeOptions[0])
    
    setRobotColor(props.robotExisting?.robot_color || "White")
    setRobotColorOption( props.robotExisting ?
                        { text: props.robotExisting.robot_color, value: props.robotExisting.robot_color } :
                        robotColorOptions[0])
    
    setName(props.robotExisting?.name || t('robots.new_robot_name'))
    setDescription(props.robotExisting?.description || '')
    setSerialId(getID())

    props.onClose()
  };

  const onStationSelected = (e: IOption) => {
    if (e.value === STATION_OPTIONS.NONE) {
      setCurrentStationOption({ text: 'No maintenance station', value: STATION_OPTIONS.NONE });
    } else {
      setCurrentStationOption(e);
      const station = props.stations.find(station => station.id === e.value);
      setSelectedStation(station);
    }
  };

  const onTypeSelected = (e: IOption) => {
    setRobotTypeOption(e);
    setRobotType(e.value as string);
  };  

  const onColorSelected = (e: IOption) => {
    setRobotColorOption(e);
    setRobotColor(e.value as string);
  };


  const serialIdError = useMemo(() => {
    return !props.robotExisting && (!!props.robots.find(robot => robot.serialNumber === serialId));
  }, [props.robotExisting, props.robots, serialId]);

  const isNameValid = /^[a-zA-Z0-9\s-]+$/.test(name) && /[a-zA-Z0-9]/.test(name);
  const isSaveDisabled = name === '' || !isNameValid || !serialId || serialId < 0 || serialIdError;

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      className={clsx(style.root, props.className)}
      shouldCloseOnOverlayClick={true}
      style={{ overlay: { zIndex: 100 } }}
      ariaHideApp={false}
    >
      <div className={style.titleBlock}>
        <div className={style.title}>{props.robotExisting ? t('robots.edit_existing') : t('robots.add_new')}</div>
      </div>
      <div className={style.row}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_name')}
          defaultValue={name}
          placeholder={t('robots.robot_name_placeholder')}
          onChange={e => setName(e.target.value)}
          error={"Name must contain letters or numbers"}
          hasError={!isNameValid}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_serial_id')}
          defaultValue={String(serialId)}
          placeholder={t('robots.robot_serial_id_placeholder')}
          onChange={e => setSerialId(parseFloat(e.target.value))}
          disabled={!!props.robotExisting}
          type="number"
          error="Serial ID must be unique"
          hasError={serialIdError}
        />
      </div>

      <div className={style.row}>
        <Dropdown
          className={style.textfield}
          currentOption={robotTypeOption}
          options={robotTypeOptions}
          onClickOption={onTypeSelected}
          description="Select robot's type:"
        />
        <Dropdown
          className={style.textfield}
          currentOption={robotColorOption}
          options={robotColorOptions}
          onClickOption={onColorSelected}
          description="Select robot's color:"
        />
      </div>

      <div className={style.row}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.sim_number')}
          defaultValue={simNumber}
          placeholder={t('robots.sim_number_placeholder')}
          onChange={e => setSimNumber(e.target.value)}
        />
        <Dropdown
          className={style.textfield}
          currentOption={currentStationOption}
          options={selectStationOptions}
          onClickOption={onStationSelected}
          description="Select maintenance station:"
        />
      </div>
      <div className={style.row}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_description')}
          defaultValue={description}
          placeholder={t('robots.robot_description_placeholder')}
          onChange={e => setDescription(e.target.value)}
        />
      </div>
      <div className={style.footer}>
        <Button.Outlined className={style.btn} disabled={saving} onClick={onCancelClick}>
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart className={style.btn} disabled={saving || isSaveDisabled} loading={saving} onClick={onSaveClick}>
          {t('common.save')}
        </Button.Standart>
      </div>
    </Modal>
  );
};

export default AddRobotModal;
