/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RobotColorEnum {
        WHITE = 'White',
        BLACK = 'Black',
        PINK = 'Pink',
        RED = 'Red',
        BLUE = 'Blue',
        GREEN = 'Green',
        YELLOW = 'Yellow',
        ORANGE = 'Orange',
        PURPLE = 'Purple',
    }