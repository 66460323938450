
export const lotTypeMap: Record<number, string> = {
  0: 'NOT_SET',
  1: 'WASHING',
  2: 'CHARGING',
  3: 'PARKING',
};

export const statusTypeMap: Record<number, string> = {
  0: 'NOT_SET',
  1: 'IN_PROGRESS',
  2: 'SUCCEEDED',
  3: 'ABORTED',
  4: 'CANCELED',
  5: 'FAILED',
}

export interface ILotEvent {
  id: number
  state_status: number 
  error_code: number
  error_msg: string  
  robot_sn: number
  timestamp: string
  lot_id: number
  type: number
}