import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { Button } from 'components/Button/Button'
import { ReactComponent as CalculateRouteSvg } from 'images/icons/calculate_route.svg'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as FinishPointSvg } from 'images/icons/transfer_route_finish.svg'
import { ReactComponent as MiddleRouteSvg } from 'images/icons/transfer_route_middle.svg'
import { ReactComponent as StartPointSvg } from 'images/icons/transfer_route_start.svg'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectHighlightedFeatureIdInList } from 'store/selectors/mission'
import { TRANSFER_CALCULATING_STATUSES, TRANSFER_TYPE } from 'utils/constants'
import getTestId from 'utils/getTestId'

import style from './CommonRouteItem.module.css'

type CommonRouteItemProps = {
  text?: string
  itemType: TRANSFER_TYPE
  id: string | number
  editing: boolean
  status?: TRANSFER_CALCULATING_STATUSES
  length?: number
  onClick: () => void
  onEdit: () => void
  onRemove: () => void
  onCancel: (e?: any) => void
  onSave: (e?: any) => void
  hideActions?: boolean
  hideCleaningType?: boolean
  onCalculate?: () => void
}

const CommonRouteItem = (props: CommonRouteItemProps) => {
  const { t } = useTranslation()
  const highlightedFeatureIdInList = useSelector(selectHighlightedFeatureIdInList)

  const highlighted = useMemo(() => {
    return highlightedFeatureIdInList === props.id
  }, [highlightedFeatureIdInList, props.id])
  const routeItemStyle = props.hideCleaningType ? style.transferRouteItemShort : style.transferRouteItem
  const icon = useMemo(() => {
    switch (props.itemType) {
      case TRANSFER_TYPE.START:
        return <StartPointSvg className={style.iconStart} />
      case TRANSFER_TYPE.MIDDLE:
        return <MiddleRouteSvg className={style.iconMiddle} />
      case TRANSFER_TYPE.FINISH:
        return <FinishPointSvg className={style.iconFinish} />
    }
  }, [props.itemType])

  return (
    <div
      className={highlighted ? clsx(routeItemStyle, style.highlighted) : routeItemStyle}
      onClick={props.onClick}
    >
      <div></div>
      <div className={style.icon}>{icon}</div>
      <div className={style.text}>{props.text}</div>
      {!props.hideCleaningType && (<div className={style.text}>{}</div>)}
      <div className={style.buttonsBlock}>
        {props.editing ? (
          <>
            <Button.Outlined className={style.smallBtn} onClick={props.onCancel}>
              {t('common.cancel')}
            </Button.Outlined>
            <Button.Outlined className={style.smallBtn} onClick={props.onSave}>
              {t('common.save')}
            </Button.Outlined>
          </>
        ) : props.status === TRANSFER_CALCULATING_STATUSES.OK && props.length ? (
          <span className={style.length}>
            <span>Length: </span>
            <span className={style.bold}>{Math.round(props.length)}m</span>
          </span>
        ) : (
          props.status && t('mission_status.' + props.status)
        )}
      </div>
      <div className={style.zoneItemColSmall}>
        {!props.hideActions && (
          <ActionsMenu>
            {props.onCalculate && (
              <ActionsMenuItem
                onClick={props.onCalculate}
                data-test-id={getTestId('calc-route')}
                disabled={
                  props.status === TRANSFER_CALCULATING_STATUSES.OK ||
                  props.status === TRANSFER_CALCULATING_STATUSES.CALCULATING
                }
              >
                <CalculateRouteSvg className={style.menuItemIcon} />
                <span data-test-id={getTestId('calc-title')}>{t('mission.calculate_route')}</span>
              </ActionsMenuItem>
            )}
            <ActionsMenuItem onClick={props.onEdit} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span data-test-id={getTestId('edit-title')}>{t('common.edit')}</span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={props.onRemove} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        )}
      </div>
    </div>
  )
}

export default CommonRouteItem
