import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import {
  addHours,
  addSeconds,
} from 'date-fns';

import { SchedulerSettings, RobotReadMissionJobInline, EventStatistics } from 'api/schema';

/** 
 * Generates a list of ProcessedEvents to occur within the query start and end,
 * based on the provided schedule.
 * @param start The start of the query, which is generally 00:00:00 of the first
 * day in the calendar view.
 * @param end The end of the query, which is generally 23:59:59 of the last day
 * in the calendar view.
 * @param task The current scheduled task, to be checked if there are any events
 * between start and end.
 * @param description Task description.
 * @param robot_name Robot name.
 * @returns List of ProcessedEvents to occur within the query start and end.
 */
export const scheduleToEvents = (
  start: Date,
  end: Date,
  task: RobotReadMissionJobInline,
  getEventColor: () => string | undefined,
  description: string,
  robot_name: string,
): ProcessedEvent[] => {
  if (!task) {
    console.warn('Task is null!');
    return [];
  }
  let eventDate: Date
  if (task.scheduler_settings && task.scheduler_settings.datetime) {
    eventDate = new Date(task.scheduler_settings.datetime.replace(" ", "T"));
  } else{
    eventDate = new Date(task.created_at.replace(" ", "T"));
    const validDateString = task.created_at.slice(0, 16); // Remove extra info
    eventDate = new Date(validDateString);
  }

  if (!task.scheduler_settings?.weekdays &&
      eventDate < start ||
      eventDate > end)  {
    return [];
  }
  const events: ProcessedEvent[] = [];
  const duration = 3;
  const dayDuration = 24 * 60 * 60 * 1000; 
  const weekDuration = 7 * dayDuration; 
  const defaultTaskName = 'Task';
  const taskName =  "Robot " + robot_name +" | Task " + task?.id + " | " + (task?.name || defaultTaskName);
  if (task.scheduler_settings?.weekdays) {
    let currentDate = new Date(Math.max(start.getTime(), eventDate.getTime()));
    const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as (keyof SchedulerSettings['weekdays'])[];
    for (const dayOfWeek of daysOfWeek) {
      if (task.scheduler_settings.weekdays[dayOfWeek] === 'true') {
        let dayOffset = daysOfWeek.indexOf(dayOfWeek) - currentDate.getDay(); 
        // Ensure dayOffset is always positive
        if (dayOffset < 0) {
          dayOffset += 7;
        }
        let startDate = new Date(currentDate.valueOf() + dayOffset * dayDuration);
        startDate.setHours(eventDate.getHours(), eventDate.getMinutes());
        events.push(...addEvents(startDate, 
                                 end, 
                                 weekDuration, 
                                 task.id, 
                                 taskName, 
                                 getEventColor, 
                                 duration, 
                                 task.scheduler_settings.except_days,
                                 description));
      }
    }
  } else if (eventDate >= start && eventDate <= end) {
      events.push({
        start: eventDate,
        end: addHours(eventDate, duration),
        event_id: task.id,
        title: taskName,
        color: getEventColor(),
        duration: duration,
        description: description,
      });
  }

  return events;
};

export const addEvents = (
  start: Date,
  end: Date,
  period: number,
  id: number,
  title: string,
  getEventColor: () => string | undefined,
  duration: number,
  except_days: string[] = [],
  description: string,
): ProcessedEvent[] => {
  let events: ProcessedEvent[] = [];

  while (start <= end) {
    if (except_days.includes(toISOStringYYYYMMDD(start))){
      start = new Date(start.valueOf() + period);
      continue;
    }
    events.push({
      start: start,
      end: addHours(start, duration),
      event_id: id,
      title: title,
      color: getEventColor(),
      description: description,
    });
    start = new Date(start.valueOf() + period);
  }

  return events;
}

const getStatsDescription = (eventStats: EventStatistics): string => {
  if (eventStats) {
    let report = "";

    const efficiency = eventStats.mission_progress.cleaned_area_actual / eventStats.mission_progress.duration_actual * 3600;
    if (efficiency) {
      report += `• Cleaning efficiency: ${efficiency.toFixed(1)} m\u00B2/h \n`;
    }

    const percent_cleaning = eventStats.mission_progress.cleaned_area_actual / eventStats.mission_progress.cleaned_area_planned * 100;
    if (percent_cleaning) {
      report += `• Cleaning progress: ${percent_cleaning.toFixed(1)}%: ${eventStats.mission_progress.cleaned_area_actual.toFixed(1)} out of ${eventStats.mission_progress.cleaned_area_planned.toFixed(1)} m\u00B2 \n`;
    }

    // const percent_moving = eventStats.mission_progress.travel_distance_actual / eventStats.mission_progress.travel_distance_planned * 100;
    // if (percent_moving) {
    //   report += `• Moving progress: ${percent_moving.toFixed(2)}%: ${eventStats.mission_progress.travel_distance_actual.toFixed(2)} out of ${eventStats.mission_progress.travel_distance_planned.toFixed(2)} m\n`;
    // }

    if (eventStats.mission_progress.duration_actual) {
      report += `• Duration: ` + secToTime(eventStats.mission_progress.duration_actual) + `\n`;
    }

    if (eventStats.mission_progress.duration_planned) {
      report += `• Planned duration: ` + secToTime(eventStats.mission_progress.duration_planned) + `\n`;
    }    

    if (eventStats.mission_progress.battery_begin && eventStats.mission_progress.battery_end) {
      const value = eventStats.mission_progress.battery_begin - eventStats.mission_progress.battery_end;
      if (value > 0)
        report += `• Battery charge used: ${value.toFixed(1)}%\n`;
    }    
    
    if (eventStats.mission_progress.water_level_begin && eventStats.mission_progress.water_level_end) {
      const value = eventStats.mission_progress.water_level_begin - eventStats.mission_progress.water_level_end;
      if (value > 0)
        report += `• Cleaned water used: ${value.toFixed(1)}%\n`;
    }  

    // if (eventStats.mission_progress.garbage_filled_begin && eventStats.mission_progress.garbage_filled_end) {
    //   const value = eventStats.mission_progress.garbage_filled_end - eventStats.mission_progress.garbage_filled_begin;
    //   if (value < 0)
    //     report += `• Garbage collected: 0%\n`;
    //   else
    //     report += `• Garbage collected: ${value}%\n`;
    // }    

    // if (eventStats.mission_progress.dirty_water_level_begin && eventStats.mission_progress.dirty_water_level_end) {
    //   const value = eventStats.mission_progress.dirty_water_level_end - eventStats.mission_progress.dirty_water_level_begin;
    //   if (value < 0)
    //     report += `• Dirty water collected: 0%\n`;
    //   else
    //     report += `• Dirty water collected: ${value.toFixed(1)}%\n`;
    // }    

    if (report == "") 
      return '';
    else
      return "Mission report:\n" + report;
  }
  return '';
};

const getEventStats = (task_id: number, date: Date, stats: EventStatistics[]): EventStatistics | null => {
  const eventStats = stats.find(stat => 
    stat.task_id === task_id && 
    toISOStringYYYYMMDD(new Date(stat.created_at)) === toISOStringYYYYMMDD(date)
  );
  if (eventStats) {
    return eventStats;
  }
  return null;
};

export const updateTaskDisplayInfo = (
  event: ProcessedEvent,
  events_statistics: EventStatistics[]
) => {
  const event_stats = getEventStats(Number(event.event_id), event.start, events_statistics);
  if (event_stats) {
    event.title = event_stats.mission_status === 4 ? '✔ ' + event.title : 
                  event_stats.mission_status === 255 ? '？ ' + event.title : 
                  event_stats.mission_status === 7 ?  '⛔ ' + event.title : 
                  event_stats.mission_status === 8 ? '⚠️ ' + event.title :
                  '⏳ ' + event.title;
    event.description += getStatsDescription(event_stats);
    event.start = new Date(event_stats.created_at.replace(" ", "T"));
    event.end = addSeconds(event.start, event_stats.mission_progress.duration_actual);
  } // ✔ ⏳ ⛔ ？ ⚠️
};

const idToColorMap: Record<number, string> = {
  1: "BlueViolet",
  2: "Crimson",
  3: "DarkCyan",
  4: "DodgerBlue",
  5: "ForestGreen",
  6: "Gold",
  7: "Indigo",
  8: "LimeGreen",
  9: "MediumOrchid",
  10: "MediumPurple",
  11: "MediumSeaGreen",
  12: "NavajoWhite",
  13: "OliveDrab",
  14: "OrangeRed",
  15: "Orchid",
  16: "PaleTurquoise",
  17: "RoyalBlue",
  18: "SlateBlue",
  19: "SpringGreen",
  20: "Tomato",
  21: "Turquoise",
  22: "Violet",
  23: "Wheat",
  24: "YellowGreen",
  25: "Aqua",
  26: "Aquamarine",
  27: "CornflowerBlue",
  28: "HotPink",
  29: "Chartreuse",
  30: "Chocolate",
  31: "CadetBlue",
  32: "DarkGoldenRod",
  33: "DarkKhaki",
  34: "DarkMagenta",
  35: "DarkOliveGreen",
  36: "DarkOrange",
  37: "DarkOrchid",
  38: "DarkRed",
  39: "DarkSalmon",
  40: "DarkSlateBlue",
  41: "DeepPink",
  42: "DeepSkyBlue",
  43: "DimGray",
  44: "FireBrick",
  45: "Gainsboro",
  46: "GhostWhite",
  47: "GoldenRod",
  48: "GreenYellow",
  49: "BurlyWood",
  50: "IndianRed",
  51: "Ivory",
  52: "Khaki",
  53: "Lavender",
  54: "LavenderBlush",
  55: "LawnGreen",
  56: "LightCoral",
  57: "LightCyan",
  58: "LightGoldenRodYellow",
  59: "LightPink",
  60: "LightSalmon",
};
const nameToColorMap: Record<string, string> = {
  "White": "DarkGrey",
  "Black": "Black",
  "Pink": "HotPink",
  "Red": "FireBrick",
  "Blue": "CornflowerBlue",
  "Green": "Green",
  "Yellow": "Gold",
  "Orange": "DarkOrange",
  "Purple": "RebeccaPurple",
};

export const getColorById = (id: number): string => {
  return idToColorMap[id] || "Gray"; // Default color
};

export const getColorByColorName = (color: string): string => {
  return nameToColorMap[color] || "Gray"; // Default color
};

function secToTime(sec: number): string {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = Math.round(sec % 60);
  let time = '';
  if (hours > 0) {
    time += hours + 'h ';
  }
  if (minutes > 0) {
    time += minutes + 'm ';
  }
  if (seconds > 0) {
    time += seconds + 's';
  }
  return time;
}

// Pad a number to 2 digits
function pad(n: number): string {
  return `${Math.floor(Math.abs(n))}`.padStart(2, '0');
}

// Get timezone offset in ISO format (+hh:mm or -hh:mm)
function getTimezoneOffset(date: Date): string {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
}

// Convert Date to local time zone ISO string that contains timezone information
export function toISOStringWithTimezone(date: Date): string {
  return (
    date.getFullYear()  +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  );
}

// Convert Date to local time zone ISO string YYYY-MM-DD
export function toISOStringYYYYMMDD(date: Date): string {
  return ('' +
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) + ''
  );
}
