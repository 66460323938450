import { Point } from 'geojson'
import { IStation, ILot } from 'utils/interfaces'
import { MainService } from './schema'

export const StationApi = {
  fetchStation: async (id: number): Promise<IStation> => {
    const stationRaw = await MainService.mainRobotStationRetrieve(id)
    return {
      id: stationRaw.id,
      station_id: stationRaw.station_id,
      online: stationRaw.online,
      name: stationRaw.name || 'Unnamed unit',
      point: stationRaw.point ? {  
        type: "Point",          
        coordinates: stationRaw.point.coordinates || [],
    } : null,      
      createdAt: stationRaw.created_at,
      updatedAt: stationRaw.updated_at,
      description: stationRaw.description || '',
    }
  },
  fetchStations: async (): Promise<IStation[]> => {
    const stationsRaw = await MainService.mainRobotStationList()
    if (!stationsRaw.results) {
      return []
    }
    const stations = stationsRaw.results.map(stationRaw => {
      return {
        id: stationRaw.id,
        station_id: stationRaw.station_id,
        online: stationRaw.online,
        name: stationRaw.name || 'Unnamed unit',
        point: stationRaw.point,
        createdAt: stationRaw.created_at,
        updatedAt: stationRaw.updated_at,
        description: stationRaw.description,
      }
    })
    return stations as IStation[]
  },

  fetchLots: async (): Promise<ILot[]> => {
    const lotsRaw = await MainService.mainRobotLotList()
    if (!lotsRaw.results) {
      return []
    }
    const lots = lotsRaw.results.map(lotsRaw => {
      const stateHistory = lotsRaw.state_history?.map(stateRaw => {
        return {
          state_status: stateRaw.state_status,
          error_code: stateRaw.error_code,
          error_msg: stateRaw.error_msg,
          timestamp: stateRaw.timestamp,
          robot_sn: stateRaw.robot_sn,
        }
      })
      return {
        id: lotsRaw.id,
        robot_station: lotsRaw.robot_station,
        type: lotsRaw.type,
        lot_id: lotsRaw.lot_id,
        state_history: stateHistory,
        createdAt: lotsRaw.created_at,
        updatedAt: lotsRaw.updated_at,
      }
    })
    return lots as ILot[]
  },
  createStation: async (name: string, station_id: number, point: Point): Promise<IStation> => {
    const stationRaw = await MainService.mainRobotStationCreate({
      name,
      station_id,
      point,
    })
    return {
      id: stationRaw.id,
      name: stationRaw.name || 'Unnamed unit',
      station_id: stationRaw.station_id,
      online: stationRaw.online,
      createdAt: stationRaw.created_at,
      updatedAt: stationRaw.updated_at,
      point: stationRaw.point
        ? {
            type: 'Point',
            coordinates: stationRaw.point.coordinates || [],
          }
        : undefined,
    }
  },
  patchStation: async (id: number, name: string, station_id: number, point: Point): Promise<IStation> => {
    const stationRaw = await MainService.mainRobotStationPartialUpdate(id, {
      name,
      station_id,
      point,
    })
    return {
      id: stationRaw.id,
      name: stationRaw.name || 'Unnamed unit',
      station_id: stationRaw.station_id,
      online: stationRaw.online,
      createdAt: stationRaw.created_at,
      updatedAt: stationRaw.updated_at,
      point: stationRaw.point
        ? {
            type: 'Point',
            coordinates: stationRaw.point.coordinates || [],
          }
        : undefined,
    }
  },
  deleteStation: async (id: number): Promise<void> => {
    await MainService.mainRobotStationDestroy(id)
  },
}
