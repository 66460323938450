import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, TextField } from '@mui/material'
import { AccountApi } from 'api/AccountApi'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo, useState } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { newPasswordSchema } from 'utils/validationSchema'
import * as yup from 'yup'

import style from '../Signin.module.css'

interface IFormInputs {
  password: string
}

const NewPassword: React.FC = () => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState(PROGRESS_TYPES.IDLE)
  const navigate = useNavigate()

  const user = useUser()
  const [urlParams] = useSearchParams()

  const token = urlParams.get('token')

  const loginProgress = useMemo(() => user?.state.loginProgress, [user?.state.loginProgress])
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ password: newPasswordSchema() })),
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    async data => {
      try {
        setProgress(PROGRESS_TYPES.WORK)
        await AccountApi.setNewPassword(token || '', data.password)
        setProgress(PROGRESS_TYPES.SUCCESS)
      } catch (error) {
        setProgress(PROGRESS_TYPES.ERROR)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      {progress !== PROGRESS_TYPES.SUCCESS && (
        <>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <div className={style.fld}>
              <TextField
                {...methods.register('password')}
                id="password"
                name="password"
                type="password"
                variant="outlined"
                autoComplete="password"
                placeholder={t('signin.passwordPlaceholder')}
                inputProps={{
                  'data-test-id': 'password',
                }}
                aria-describedby="password-error-text"
                error={!!methods.formState.errors.password}
                fullWidth
              />
              <FormHelperText id="password-error-text">
                {t(methods.formState.errors.password?.message || '')}
                {progress === PROGRESS_TYPES.ERROR && 'Error changing password'}
              </FormHelperText>
            </div>
            <Button.Standart
              dataTestId={'submit-btn'}
              disabled={loginProgress === PROGRESS_TYPES.WORK}
              loading={loginProgress === PROGRESS_TYPES.WORK}
              className={style.signinButton}
            >
              {t('restore_password.set_new')}
            </Button.Standart>
          </form>
          <div data-test-id={'restore'} className={style.forgotPasswordLink}>
            {t('restore_password.already_have')}
            <Link className={style.href} to={PATH.SIGNIN} data-test-id={getTestId('link-forgot-password')}>
              {t('restore_password.signin')}
            </Link>
          </div>
        </>
      )}
      {progress === PROGRESS_TYPES.SUCCESS && (
        <>
          <div className={style.restoreText}>{t('restore_password.new_password_set')}</div>
          <Button.Standart
            dataTestId={'signin-btn'}
            disabled={false}
            className={style.signinButton}
            onClick={() => navigate(PATH.ROBOT_LIST)}
          >
            {t('restore_password.signin')}
          </Button.Standart>
        </>
      )}
    </div>
  )
}

export default NewPassword
