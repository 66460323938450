import { Tabs } from '@mui/material'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { RobotApi } from 'api/RobotApi'
import StationDetails from './StationDetails/StationDetails'
import EventsLog from './EventsLog/EventsLog'
import { IRobotInfo } from 'utils/interfaces'

const RobotLeftBlock = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)
  const [robots, setRobots] = useState<IRobotInfo[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const fetchRobots = async () => {
    try {
      const robots = await RobotApi.fetchRobots()
      setRobots(robots)
    } catch (error) {
      console.log(error)
    }
  }

  useEffectOnce(() => {
    fetchRobots()
  })

  return (
    <div className="flex-column">
      <Tabs
        value={tab}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        sx={{ height: 45, minHeight: 45 }}
      >
        <StyledTab
          label={'Station details'}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 320 }}
          disableRipple
        />
        <StyledTab
          label={'Events log'}
          sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, maxWidth: 320 }}
          disableRipple
        />
      </Tabs>
      <TabPanel value={tab} index={0} disablePadding={true}>
        <StationDetails robots = {robots} />
      </TabPanel>
      <TabPanel value={tab} index={1} disablePadding={true}>
        <EventsLog robots = {robots}/>
      </TabPanel>
    </div>
  )
}

export default RobotLeftBlock
