import clsx from 'clsx'
import { format } from 'date-fns'
import { ReactComponent as BatterySvg } from 'images/icons/battery_half.svg'
import { ReactComponent as CleanWater } from 'images/icons/clean_water.svg'
import { ReactComponent as DirtyWater} from 'images/icons/dirty_water.svg'
import { ReactComponent as ContainerSvg } from 'images/icons/container.svg'
import { ReactComponent as GarbageBin } from 'images/icons/garbage.svg'
import { ReactComponent as NoConnectionSvg } from 'images/icons/signal_wifi_off.svg'
import { ReactComponent as ConnectionSvg } from 'images/icons/signal_wifi_on.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectRobot, selectRobotTelemetry } from 'store/selectors/robot'
import { ROBOT_NETWORK_STATUSES } from 'utils/constants'

import style from '../RobotDetails.module.css'

const RobotInfo = () => {
  const { t } = useTranslation()
  const telemetry = useSelector(selectRobotTelemetry)
  const robot = useSelector(selectRobot)

  const getTextStatus = (percents: number | undefined) => {
    if (!percents) {
      return '-'
    }
    if (percents > 60) {
      return t('robot.good')
    } else if (percents > 30) {
      return t('robot.normal')
    } else {
      return t('robot.low')
    }
  }

  const isOnline = robot.robotInfo?.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE

  return (
    <div className={style.root}>
      <div className={style.tableRow}>
        <div>{isOnline ? <ConnectionSvg /> : <NoConnectionSvg />}</div>
        <div className={style.textAlignLeft}>Status</div>
        <div className={clsx(style.chip, style.centered, style.locked, isOnline ? style.online : '')}>{`${
          isOnline ? 'Online' : 'Offline'
          } `}</div>
        <div>
          <div className={style.percents}>
            {telemetry?.timestamp ? format(new Date(telemetry.timestamp * 1000), 'dd LLLL yyyy') : '-'}
          </div>
          <div className={style.percents}>
            {telemetry?.velocity !== undefined && `Spd: ${Math.round(telemetry?.velocity * 10) / 10}m/s `}
            {telemetry?.timestamp && format(new Date(telemetry.timestamp * 1000), 'HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className={style.tableRow}>
        <div>
          <BatterySvg />
        </div>
        <div className={style.textAlignLeft}>Battery</div>
        <div className={clsx(style.chip, style.centered, style.locked)}>{getTextStatus(telemetry?.batteryPower)}</div>
        <div className={style.percents}>
          {telemetry?.batteryPower !== undefined ? Math.round(telemetry.batteryPower) + '%' : '-'}
        </div>
      </div>
      <div className={style.tableRow}>
        <div>
          <CleanWater />
        </div>
        <div className={style.textAlignLeft}>Clean water</div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          {getTextStatus(telemetry?.waterTankFilling)}
        </div>
        <div className={style.percents}>
          {telemetry?.waterTankFilling !== undefined ? Math.round(telemetry.waterTankFilling) + '%' : '-'}
        </div>
      </div>
      {telemetry && telemetry.dirtyWaterTankFilling !== 0.0 && (
        <div className={style.tableRow}>
          <div>
            <DirtyWater />
          </div>
          <div className={style.textAlignLeft}>Dirty water</div>
          <div className={clsx(style.chip, style.centered, style.locked)}>{getTextStatus(telemetry?.dirtyWaterTankFilling)}</div>
          <div className={style.percents}>
            {telemetry?.dirtyWaterTankFilling !== undefined ? Math.round(telemetry.dirtyWaterTankFilling) + '%' : '-'}
          </div>
        </div>
      )} 
      <div className={style.tableRow}>
        <div>
          <GarbageBin />
        </div>
        <div className={style.textAlignLeft}>Garbage tank</div>
        <div className={clsx(style.chip, style.centered, style.locked)}>{getTextStatus(telemetry?.trashFullness)}</div>
        <div className={style.percents}>
          {telemetry?.trashFullness !== undefined ? Math.round(telemetry.trashFullness) + '%' : '-'}
        </div>
      </div>
    </div>
  )
}

export default RobotInfo
