import { MainApi } from 'api/MainApi'
import { Button } from 'components/Button/Button'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import Title from 'components/Title/Title'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'

import style from './RenameRecordedRouteModal.module.css'

interface IDialogProps {
  isOpen: boolean
  routeZoneName: string
  onSave: () => void
  onClose: () => void
}

const RenameRecordedRouteModal = ({ isOpen, routeZoneName, onClose, onSave }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [tempRouteZoneName, setTempRouteZoneName] = useState(routeZoneName)
  const [hasNameError, setNameHasError] = useState(false)
  const [actionInProgress, setActionInProgress] = useState(false)

  const onRouteZoneNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setNameHasError(true)
    } else {
      setNameHasError(false)
      setTempRouteZoneName(e.target.value)
    }
  }
 
  const onCancelClicked = () => {
    setTempRouteZoneName(routeZoneName)
    if (!actionInProgress) {
      onClose()
    }
  }

  const onSaveClicked = async () => {
    setActionInProgress(true)

    try {
      if (tempRouteZoneName !== routeZoneName) {
        await MainApi.renameRouteRecordedZone(routeZoneName, tempRouteZoneName)
        onSave()
        onClose()
      }

    } catch (error) {
      console.log(error)
    }

    setActionInProgress(false)
    onClose()
  }

  const isSaveButtonDisabled = false;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text={t('view_zones.rename_title')} className={style.title} />
        </div>
        <div className={style.content}>
          <div className={style.row}>
            <TextFieldDescribed
              className={style.textfield}
              description={""}
              error={t('view_zones.name_error')}
              placeholder={t('view_zones.name_placeholder')}
              hasError={hasNameError}
              defaultValue={tempRouteZoneName}
              onChange={onRouteZoneNameChanged}
            />
          </div>
        </div>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked} disabled={actionInProgress}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            disabled={isSaveButtonDisabled || actionInProgress}
            loading={actionInProgress}
            className={style.btn}
            onClick={onSaveClicked}
          >
            {t('mission_settings.label_save')}
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default RenameRecordedRouteModal
