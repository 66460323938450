import clsx from 'clsx'
import Box from 'components/Box/Box'
import { Button } from 'components/Button/Button'
import { Feature, Geometry, GeoJsonProperties } from 'geojson'
import { zoomMapTo } from 'utils/mapUtils'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import { MissionActions } from 'store/actions/mission'
import { useDispatch } from 'react-redux'
import Title from 'components/Title/Title'
import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MapboxMap } from 'react-map-gl'
import { IMapboxElement, ICleaningZone, IRoute } from 'utils/interfaces'
import CleaningZonesOnMap from '../../Mission/MissionView/MissionMap/Layers/CleaningZonesOnMap'
import CleaningRoutesOnMap from '../../Mission/MissionView/MissionMap/Layers/CleaningRoutesOnMap'
import CleaningPointsOnMap from '../../Mission/MissionView/MissionMap/Layers/CleaningPointsOnMap'
import CleaningZonesNamesOnMap from '../../Mission/MissionView/MissionMap/Layers/CleaningZonesNamesOnMap'

import RecordedRouteItem from './RecordedRouteItem/RecordedRouteItem'

import style from './RecordedRouteListView.module.css'

const RecordedRouteListView = ({
  cleaningZones,
  cleaningRoutes,
  onZonesChanged,
}: {
  cleaningZones: ICleaningZone[]
  cleaningRoutes: IRoute[]
  onZonesChanged: () => void
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mapRef = useRef<IMapboxElement>(null)
  const [map, setMap] = useState<MapboxMap | undefined>(undefined)
  const [highlightedZoneName, setHighlightedZoneName] = useState<string | undefined>(undefined)
  const [zoomZoneName, setZoomZoneName] = useState<string | undefined>(undefined)


  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (!map) return;
    
    let featuresToZoom: Feature[] = [];

    if (zoomZoneName) {
      featuresToZoom.push(cleaningZones.find(zone => zone.properties.name === highlightedZoneName) as Feature);
    }

    if (featuresToZoom.length > 0) {
      zoomMapTo(map, featuresToZoom);
    }
  }, [zoomZoneName])

  const onMapInited = () => {
    const map = mapRef.current?.getMap()
    if (map) {
      setMap(map)
    }

    const features: Feature<Geometry, GeoJsonProperties>[] = [
      ...cleaningZones,
    ];

    zoomMapTo(map, features)
    const setHighlightedFeature = (name: string | undefined) => {
      setHighlightedZoneName(name)
    }
    map?.on('click', () => setHighlightedFeature(undefined))
    map?.on('click', 'gl-draw-polygon-fill-cleaning-zone', e =>
      setHighlightedFeature(e?.features && e?.features[0].properties?.name)
    )
  }

  const onZoneClicked = (name: string) => {
    setHighlightedZoneName(name)
    setZoomZoneName(name)
  }

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Title text={'Recorded routes'} />{' '}
      </div>
      <div className={style.main}>
        <Box className={style.box}>
          <div className={style.tableWrapper}>
            <div className={style.tableHeader}>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>#</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>Name</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>Area</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>Route length</div>
              <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>Created at</div>              
            </div>
            {cleaningZones.length ? (
              <div className={style.table}>
                {cleaningZones.slice() 
                .sort((a, b) => new Date(b.properties.createdAt).toISOString().localeCompare(new Date(a.properties.createdAt).toISOString()))
                .map((zone, index) => (
                  <RecordedRouteItem
                    zone={zone}
                    index={index + 1}
                    highlighted={zone.properties.name === highlightedZoneName}
                    onZoneChanged={onZonesChanged}
                    onClicked={() => onZoneClicked(zone.properties.name)}
                  />
                ))}
              </div>
            ) : (
              <div className={style.emptyWrapper}>
                <div className={style.emptyTitle}>{t('view_zones.empty_zone_list')}</div>
                <div className={style.emptyDescription}>{t('view_zones.empty_zone_list_description')}</div>
              </div>
            )}
          </div>
        </Box>
        <MapboxCommon ref={mapRef} disableRuler={true} overlayItems={[]} onMapInited={onMapInited}>
          <CleaningZonesOnMap cleaningZones={cleaningZones} />
          <CleaningRoutesOnMap cleaningRoutes={cleaningRoutes} />
          <CleaningPointsOnMap
            cleaningRoutes={cleaningRoutes}
            launchPoints={[]}
          />
          <CleaningZonesNamesOnMap cleaningZones={cleaningZones} />
        </MapboxCommon>
      </div>
    </div>
  )
}

export default RecordedRouteListView
