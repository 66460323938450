import Box from 'components/Box/Box'
import Title from 'components/Title/Title'
import { Polygon } from 'geojson'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import {
  selectCleaningZones,
  selectMission,
  selectMissionCalculatingStatus,
  selectRestrictZones,
} from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import {
  MISSION_CALCULATING_STATUSES,
  MISSION_PAGE_MODES,
  MISSION_ZONE_TYPES,
  TIMEOUT_FETCHING_STATUS,
} from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { RobotTypeEnum } from 'api/schema'

import CalculatingLoader from './CalculatingLoader/CalculatingLoader'
import MissionButtons from './MissionButtons/MissionButtons'
import MissionMap from './MissionMap/MissionMap'
import DrawZoneMode from './Modes/DrawZone/DrawZoneMode'
import ViewZonesMode from './Modes/ViewZone/ViewZonesMode'
import {
  TransferRouteStatus,
  CleaningZoneStatus,
} from 'api/schema/index'
import style from './MissionView.module.css'

const MissionView = () => {
  const mission = useSelector(selectMission)
  const cleaningZones = useSelector(selectCleaningZones)
  const restrictZones = useSelector(selectRestrictZones)
  const calculatingStatus = useSelector(selectMissionCalculatingStatus)
  const [ready, setReady] = useState<[number, number, number, number]>([0, 0, 0, 0]); // OverallCleaning, ReadyCleaning, OverallTransfer, ReadyTransfer
  const [calculationMode, setCalculationMode] = useState<'all' | 'single' | undefined>(undefined); 
  const [calculationIsActive, setCalculationIsActive] = useState<boolean>(false); 
  const [hideCleaningType, setHideCleaningType] = useState<boolean>(!mission.missionInfo || !mission.missionInfo.robot_type || mission.missionInfo.robot_type != RobotTypeEnum.SWEEPER_SCRUBBER); 
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      if (mission.missionInfo) {
        try {
          const response = await store.dispatch(missionThunks.fetchCalculationStatus(mission.missionInfo?.id));
          const overallCleaning = cleaningZones.length;
          const overallTransfers = overallCleaning > 0 ? overallCleaning - 1 : 1;
          let readyCleaning = 0;
          let readyTransfer = 0;
          
          if (!response)
            return
          
          // An additional logic to control calculationMode state.
          // It needs for the correct CalculatingLoader view displaying
          if (!calculationIsActive && 
              response.status === MISSION_CALCULATING_STATUSES.CALCULATING && 
              calculationMode !== undefined){
            setCalculationIsActive(true)
          }

          // Get readyCleaning count
          if (response.cleaning_zones_statuses.length > 0) {
            const cleaningReadyCount: number = response.cleaning_zones_statuses.filter(
              (zone: CleaningZoneStatus) => zone.status === "ok"
            ).length;
            readyCleaning += cleaningReadyCount;
          }
          // Get readyTransfer count
          if (response.transfer_routes_statuses.length > 0) {
            const transferReadyCount: number = response.transfer_routes_statuses.filter(
              (zone: TransferRouteStatus) => zone.status === "ok"
            ).length;
            readyTransfer += transferReadyCount;
          }
          setReady([overallCleaning, readyCleaning, overallTransfers, readyTransfer]);
          
          // Update page case
          if (response.status === MISSION_CALCULATING_STATUSES.CALCULATING && 
            calculationMode === undefined){
              if (response.cleaning_zones_statuses.filter((zone: CleaningZoneStatus) => zone.status === "calculating").length > 1){
                setCalculationMode('all')
              } else {
                setCalculationMode('single')
              }
          }

          // Reset CalculationMode
          if (calculationIsActive && 
              response.status !== MISSION_CALCULATING_STATUSES.CALCULATING){
            setCalculationIsActive(false)
            setCalculationMode(undefined)
          }
        } catch (error) {
          console.log('Unable to get mission status')
        }
      }
    };

    fetchData(); // Fetch data initially

    const interval = setInterval(fetchData, TIMEOUT_FETCHING_STATUS);

    return () => clearInterval(interval);
  }, [mission, calculatingStatus, calculationIsActive, calculationMode]);

  useEffect(() => {
    setHideCleaningType(
      !mission.missionInfo || 
      !mission.missionInfo.robot_type || 
      mission.missionInfo.robot_type != RobotTypeEnum.SWEEPER_SCRUBBER)
  } , [mission.missionInfo])

  const onSaveZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!mission.tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      store.dispatch(
        missionThunks.createCleaningZone(
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon,
          mission.tempZone.properties.filename,
          mission.tempLaunchPoint?.geometry
        )
      )
    } else if (zoneType === MISSION_ZONE_TYPES.RESTRICT) {
      store.dispatch(missionThunks.createRestrictZone(zoneName, zoneDescription, mission.tempZone.geometry as Polygon))
    }
  }

  const onSaveChangedZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!mission.tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      await store.dispatch(
        missionThunks.editCleaningZone(
          mission.tempZone.properties?.zone_id,
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon,
          mission.tempZone.properties.filename,
          mission.tempLaunchPoint?.geometry
        )
      )
      if (mission.tempZone.properties.filename) {
        await store.dispatch(missionThunks.fetchMissionBackground(mission.missionInfo?.id || -1))
      }
    } else {
      store.dispatch(
        missionThunks.editRestrictZone(
          mission.tempZone.properties?.zone_id,
          zoneName,
          zoneDescription,
          mission.tempZone.geometry as Polygon
        )
      )
    }
  }

  const modeList = {
    [`${MISSION_PAGE_MODES.DRAW_ZONE}`]: <DrawZoneMode onSaveClicked={onSaveZoneClicked} />,
    [`${MISSION_PAGE_MODES.VIEW_ZONES}`]: <ViewZonesMode cleaningZones={cleaningZones} 
                                           restrictZones={restrictZones}
                                           setCalculationMode={setCalculationMode}
                                           hideCleaningType={hideCleaningType} />,
    [`${MISSION_PAGE_MODES.EDIT_ZONE}`]: (
      <DrawZoneMode onSaveClicked={onSaveChangedZoneClicked} zone={mission.editingZone} />
    ),
  }

  return (
    <>
      <CalculatingLoader 
        visible={calculatingStatus === MISSION_CALCULATING_STATUSES.CALCULATING && calculationMode !== undefined} 
        ready={ready} 
        mode={calculationMode} 
      />
      <>
        <div className={style.titleBlock}>
          <Title text={mission.missionInfo?.name || ''} />
          <div className={clsx('text-16-300', style.titleId)}>
          {t('missions.id')}: {mission.missionInfo?.id}
          </div>
        </div>
        <div className={style.main}>
          <div className={style.leftBlock}>
            <MissionButtons setCalculationMode={setCalculationMode} />
            <Box className={style.box}>{modeList[mission.mode]}</Box>
          </div>
          <div className={style.map}>
            <MissionMap />
          </div>
        </div>
      </>
    </>
  )
}

export default MissionView
