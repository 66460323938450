import { Layer, RasterLayer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { selectGeoTiffLayer } from 'store/selectors/mission'

const restrictZonesFillLayer: RasterLayer = {
  id: 'geo-tiff-layer',
  type: 'raster',
  paint: {
    'raster-opacity': 1,
  },
  minzoom: 14,
  maxzoom: 24,
}

const GeoTiffLayer = ({ show }: { show: boolean }) => {
  const geoTiffLayer = useSelector(selectGeoTiffLayer)

  return geoTiffLayer.layer && geoTiffLayer.layer.urlTiles ? (
    <Source
      id="geo-tiff"
      type="raster"
      scheme="tms"
      tiles={[geoTiffLayer.layer?.urlTiles.replace('https://nginx', '') + '/{z}/{x}/{y}.png']}
    >
      <Layer {...restrictZonesFillLayer} layout={{ visibility: show ? 'visible' : 'none' }} />
    </Source>
  ) : null
}

export default GeoTiffLayer
