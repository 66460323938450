import AddStationModal from '../AddStationModal/AddStationModal'
import { StationApi } from 'api/StationApi'
import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { format } from 'date-fns'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IStation, IRobotInfo, ILot } from 'utils/interfaces'
import { lotTypeMap}  from 'utils/lotInterfaces'

import style from './StationItem.module.css'

const StationItem = ({
  station,
  onStationUpdated,
  lots,
  stations,
  robots,
  hightlighted,
  onStationsChanged,
}: {
  station: IStation
  lots: ILot[]
  stations: IStation[]
  robots: IRobotInfo[]
  onStationUpdated: () => void
  hightlighted: boolean
  onStationsChanged: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToStation = (station_id: number) => {
    navigate(`${PATH.STATION}${station_id}`)
  }
  const [deleteStationDialog, setDeleteStationDialog] = useState(false)
  const [editingStation, setEditingStation] = useState(false)

  const deleteStation = async () => {
    try {
      await StationApi.deleteStation(station.id)
      setDeleteStationDialog(false)
      onStationUpdated()
    } catch (error) {
    console.log('Error deleting station:')
    }
  }

  return (
    <>
      <div
        className={hightlighted ? clsx(style.tableRow, style.highlighted) : style.tableRow}
        onClick={() => goToStation(station.id)}
        key={station.id}
      >
        <span className={style.tableCol}>{station.station_id}</span>
        <span className={style.tableCol}>{station.name}</span>
        <span className={style.tableCol}>
          {lots.filter(lot => lot.robot_station === station.id)
           .map(lot => lotTypeMap[lot.type])
           .join(', \n ')}
        </span>
        <span className={style.tableCol}>
          {robots.filter(robot => robot.stationId === station.id)
           .map(robot => robot.name)
           .join(', \n')}
        </span>
        <span className={station.online ? clsx(style.tableCol, style.online) : style.tableCol}>
          {station.online ? 'Online' : 'Offline'}
        </span>
        <span className={style.tableCol}>{station.updatedAt ? format(new Date(station.updatedAt), 'dd.MM.yyyy HH:mm') : ''}</span>
        <div className={style.zoneItemColSmall}>
          <ActionsMenu>
            <ActionsMenuItem onClick={() => setEditingStation(true)} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span className={style.menuItemText} data-test-id={getTestId('edit-title')}>
                {t('common.edit')}
              </span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={() => setDeleteStationDialog(true)} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        </div>
      </div>
      <ConfirmDialog
        title={t('stations.delete_question')}
        isOpen={deleteStationDialog}
        onClose={() => setDeleteStationDialog(false)}
        onConfirmClick={deleteStation}
        confirmText={t('common.delete')}
        danger={true}
      />
      <AddStationModal
        isOpen={editingStation}
        stations={stations}
        onClose={() => setEditingStation(false)}
        onSave={onStationUpdated}
        stationExisting={station}
        onStationsChanged={onStationsChanged}
      />
    </>
  )
}

export default StationItem
