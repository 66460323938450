import { MainApi } from 'api/MainApi';
import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import { ICleaningZone, IRoute } from 'utils/interfaces'

import RecordedRouteListView from './RecordedRouteListView/RecordedRouteListView'

const RecordedRouteList = () => {
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [cleaningZones, setCleaningZones] = useState<ICleaningZone[]>([])
  const [cleaningRoutes, setCleaningRoutes] = useState<IRoute[]>([])

  const fetchData = async () => {
    try {
      const response = await MainApi.fetchRouteRecordedZones();
      setCleaningZones(response.zones)
      setCleaningRoutes(response.routes)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  useEffectOnce(() => {
    const initialize = async () => {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      await fetchData()
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    }
    initialize()
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: (
      <RecordedRouteListView
        cleaningZones={cleaningZones} 
        cleaningRoutes={cleaningRoutes}   
        onZonesChanged={fetchData}
      />
    ),
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <div>Error</div>,
  }

  return stateList[loadingProgress]
}

export default RecordedRouteList
