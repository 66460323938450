import { createSlice } from '@reduxjs/toolkit'
import { MissionXyzLayerStatus } from 'api/schema'
import { CleaningZoneStatus } from 'api/schema/models/CleaningZoneStatus'
import {
  DEFAULT_VIZUALIZATION_SETTINGS,
  GEOTIFF_LAYER_STATUSES,
  MISSION_CALCULATING_STATUSES,
  MISSION_MAP_DRAWING_MODES,
  MISSION_PAGE_MODES,
  PROGRESS_TYPES,
} from 'utils/constants'
import {
  ICleaningZone,
  IFeature,
  IGeoTiffLayer,
  ILaunchPoint,
  IMission,
  IMissionInfo,
  IMissionState,
  IPoint,
  IRoute,
  ITransferRoute,
  IVisualizationSettings,
  IZone,
  IZoneTransferRoute,
} from 'utils/interfaces'

const initialState: IMissionState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  savingZoneProgress: PROGRESS_TYPES.IDLE,
  mode: MISSION_PAGE_MODES.VIEW_ZONES,
  mapMode: MISSION_MAP_DRAWING_MODES.VIEW_ZONES,
  missionInfo: undefined,
  restrictZones: [],
  cleaningZones: [],
  cleaningRoutes: [],
  launchPoints: [],
  tempZone: undefined,
  tempLaunchPoint: undefined,
  editingLaunchPoint: undefined,
  highlightedFeature: undefined,
  highlightedFeatureIdInList: undefined,
  editingZone: undefined,
  status: MISSION_CALCULATING_STATUSES.NEW,
  isReadyForCalculation: false,
  transferRoutes: [],
  editingTransfer: undefined,
  tempTransfer: undefined,
  showCleaningZones: true,
  showRestrictZones: true,
  geoTiffLayer: {
    layer: undefined,
    uploadingProgress: PROGRESS_TYPES.IDLE,
    show: true,
    deletingProgress: PROGRESS_TYPES.IDLE,
  },
  missionPoints: {
    startPoint: undefined,
    finishPoint: undefined,
    tempPoint: undefined,
    editingPoint: undefined,
    routes: [],
  },
  tempVisualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
  visualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
}

export const missionSlice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    setLoadingProgress: (state: IMissionState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setMission: (state: IMissionState, action: { payload: IMission }) => {
      state.missionInfo = action.payload.mission
      state.visualSettings = action.payload.visualizationSettings
      state.tempVisualSettings = action.payload.visualizationSettings
      state.cleaningRoutes = action.payload.cleaningRoutes
      state.cleaningZones = action.payload.cleaningZones
      state.restrictZones = action.payload.restrictZones
      state.launchPoints = action.payload.launchPoints
      state.transferRoutes = action.payload.transferRoutes
      state.isReadyForCalculation = action.payload.isReadyForCalculation
      state.status = action.payload.status
      state.geoTiffLayer.layer = action.payload.geoTiffLayer
      state.missionPoints.startPoint = action.payload.startingPoint
      state.missionPoints.finishPoint = action.payload.finishingPoint
      state.missionPoints.routes = action.payload.pointRoutes || []
    },
    setCalculatingStatus: (state: IMissionState, action: { payload: MISSION_CALCULATING_STATUSES }) => {
      state.status = action.payload
    },
    setZonesStatus: (state: IMissionState, action: { payload: CleaningZoneStatus[] }) => {
      action.payload.forEach(cleaningZoneNewStatus => {
        const cleaningZone = state.cleaningZones.find(zone => zone.properties?.zone_id === cleaningZoneNewStatus.id)
        if (cleaningZone && cleaningZone.properties) {
          cleaningZone.properties.status = cleaningZoneNewStatus.status
            ? (cleaningZoneNewStatus.status as MISSION_CALCULATING_STATUSES)
            : MISSION_CALCULATING_STATUSES.CALCULATING
          cleaningZone.properties.readyForCalculation = cleaningZoneNewStatus.is_ready_to_start_calculation
        }
      })
    },
    setLayerStatus: (state: IMissionState, action: { payload: MissionXyzLayerStatus[] }) => {
      const status = action.payload.find(layerStatus => layerStatus.id === state.geoTiffLayer.layer?.id)
      if (status && state.geoTiffLayer.layer) {
        state.geoTiffLayer.layer.status = (status.status as GEOTIFF_LAYER_STATUSES) || GEOTIFF_LAYER_STATUSES.NEW
      }
    },
    setIsReadyForCalculation: (state: IMissionState, action: { payload: boolean }) => {
      state.isReadyForCalculation = action.payload
    },
    setMissionInfo: (state: IMissionState, action: { payload: IMissionInfo }) => {
      state.missionInfo = action.payload
    },
    setMissionName: (state: IMissionState, action: { payload: string }) => {
      if (state.missionInfo) {
        state.missionInfo.name = action.payload
      }
    },

    setMissionRobotType: (state: IMissionState, action: { payload: string }) => {
      if (state.missionInfo) {
        state.missionInfo.robot_type = action.payload
      }
    },

    setMissionTransportSpeed: (state: IMissionState, action: { payload: number }) => {
      if (state.missionInfo) {
        state.missionInfo.transport_speed = action.payload
      }
    },
    
    setMissionCleaningSpeed: (state: IMissionState, action: { payload: number }) => {
      if (state.missionInfo) {
        state.missionInfo.cleaning_speed = action.payload
      }
    },

    setCleaningZones: (state: IMissionState, action: { payload: ICleaningZone[] }) => {
      state.cleaningZones = action.payload
    },
    editCleaningZone: (state: IMissionState, action: { payload: ICleaningZone }) => {
      state.cleaningZones = state.cleaningZones.map(zone =>
        zone.properties?.zone_id === action.payload.properties?.zone_id ? action.payload : zone
      )
    },
    editRestrictZone: (state: IMissionState, action: { payload: IZone }) => {
      state.restrictZones = state.restrictZones.map(zone =>
        zone.properties?.zone_id === action.payload.properties?.zone_id ? action.payload : zone
      )
    },
    editTransferRoute: (state: IMissionState, action: { payload: IZoneTransferRoute }) => {
      state.transferRoutes = state.transferRoutes.map(transfer =>
        transfer.properties?.id === action.payload.properties?.id ? action.payload : transfer
      )
    },
    setRestrictZones: (state: IMissionState, action: { payload: IZone[] }) => {
      state.restrictZones = action.payload
    },
    setMode: (state: IMissionState, action: { payload: MISSION_PAGE_MODES }) => {
      state.mode = action.payload
    },
    setMapMode: (state: IMissionState, action: { payload: MISSION_MAP_DRAWING_MODES }) => {
      state.mapMode = action.payload
    },
    setHighlightedFeature: (state: IMissionState, action: { payload: IFeature | undefined }) => {
      state.highlightedFeature = action.payload
    },
    setEditingZone: (state: IMissionState, action: { payload: IZone | undefined }) => {
      state.editingZone = action.payload
    },
    setEditingLaunchPoint: (state: IMissionState, action: { payload: ILaunchPoint | undefined }) => {
      state.editingLaunchPoint = action.payload
    },
    addLaunchPoint: (state: IMissionState, action: { payload: ILaunchPoint }) => {
      state.launchPoints.push(action.payload)
    },
    deleteLaunchPoint: (state: IMissionState, action: { payload: number }) => {
      state.launchPoints = state.launchPoints.filter(
        launch_point => launch_point.properties?.zone_id !== action.payload
      )
    },
    setTempZone: (state: IMissionState, action: { payload: IZone | undefined }) => {
      state.tempZone = action.payload
    },
    setTempLaunchPoint: (state: IMissionState, action: { payload: ILaunchPoint | undefined }) => {
      state.tempLaunchPoint = action.payload
    },
    deleteRoute: (state: IMissionState, action: { payload: number }) => {
      state.cleaningRoutes = state.cleaningRoutes.filter(route => route.properties?.zone_id !== action.payload)
    },
    addRoute: (state: IMissionState, action: { payload: IRoute }) => {
      state.cleaningRoutes.push(action.payload)
    },
    deleteRoutes: (state: IMissionState) => {
      state.cleaningRoutes = []
    },
    setSavingZoneProgress(state: IMissionState, action: { payload: PROGRESS_TYPES }) {
      state.savingZoneProgress = action.payload
    },
    setEditingTransfer: (state: IMissionState, action: { payload: ITransferRoute | undefined }) => {
      state.editingTransfer = action.payload
    },
    setTempTransfer: (state: IMissionState, action: { payload: ITransferRoute | undefined }) => {
      state.tempTransfer = action.payload
    },
    setTransferRoutes: (state: IMissionState, action: { payload: IZoneTransferRoute[] }) => {
      state.transferRoutes = action.payload
    },
    setHighlightedFeatureInList: (state: IMissionState, action: { payload: string | number | undefined }) => {
      state.highlightedFeatureIdInList = action.payload
    },
    setShowCleaningZones: (state: IMissionState, action: { payload: boolean }) => {
      state.showCleaningZones = action.payload
    },
    setShowRestrictZones: (state: IMissionState, action: { payload: boolean }) => {
      state.showRestrictZones = action.payload
    },
    setShowGeoTiffLayer: (state: IMissionState, action: { payload: boolean }) => {
      state.geoTiffLayer.show = action.payload
    },
    setGeoTiffLayer: (state: IMissionState, action: { payload: IGeoTiffLayer | undefined }) => {
      state.geoTiffLayer.layer = action.payload
    },
    setGeoTiffLayerUploadingProgress: (state: IMissionState, action: { payload: PROGRESS_TYPES }) => {
      state.geoTiffLayer.uploadingProgress = action.payload
    },
    setGeoTiffLayerDeletingProgress: (state: IMissionState, action: { payload: PROGRESS_TYPES }) => {
      state.geoTiffLayer.deletingProgress = action.payload
    },
    setMissionStartPoint: (state: IMissionState, action: { payload: IPoint | undefined }) => {
      state.missionPoints.startPoint = action.payload
    },
    setMissionFinishPoint: (state: IMissionState, action: { payload: IPoint | undefined }) => {
      state.missionPoints.finishPoint = action.payload
    },
    setMissionEditingPoint: (state: IMissionState, action: { payload: IPoint | undefined }) => {
      state.missionPoints.editingPoint = action.payload
    },
    setMissionTempPoint: (state: IMissionState, action: { payload: IPoint | undefined }) => {
      state.missionPoints.tempPoint = action.payload
    },
    setMissionTransferRoutes: (state: IMissionState, action: { payload: Array<ITransferRoute> }) => {
      state.missionPoints.routes = action.payload
    },
    editMissionTransfer: (state: IMissionState, action: { payload: ITransferRoute }) => {
      state.missionPoints.routes = state.missionPoints.routes.map(transfer =>
        transfer.properties?.id === action.payload.properties?.id ? action.payload : transfer
      )
    },
    deleteMissionTransfer: (state: IMissionState, action: { payload: number | undefined }) => {
      if (action.payload) {
        state.missionPoints.routes = state.missionPoints.routes.filter(route => route.properties?.id !== action.payload)
      }
    },
    setTempVisualSettings: (state: IMissionState, action: { payload: IVisualizationSettings }) => {
      state.tempVisualSettings = action.payload
    },
    setVisualSettings: (state: IMissionState, action: { payload: IVisualizationSettings }) => {
      state.visualSettings = action.payload
    },
    resetMission: () => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const MissionActions = missionSlice.actions

export default missionSlice.reducer
