import { RobotApi } from 'api/RobotApi'
import { StationApi } from 'api/StationApi'
import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import { IStation, IRobotInfo, ILot } from 'utils/interfaces'
import { ROBOT_NETWORK_STATUSES } from 'utils/constants'

import StationListView from './StationListView/StationListView'

const StationList = () => {
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [robots, setRobots] = useState<IRobotInfo[]>([])
  const [stations, setStations] = useState<IStation[]>([])
  const [lots, setLots] = useState<ILot[]>([])

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const robots = await RobotApi.fetchRobots()
      setRobots(robots)
      const stations = await StationApi.fetchStations()
      setStations(stations)
      const lots = await StationApi.fetchLots()
      setLots(lots)
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  const fetchStations = async () => {
    const stations = await StationApi.fetchStations()
    setStations(stations)
  }

  useEffectOnce(() => {
    fetchData()
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: (
      <StationListView 
        stations={stations.sort((a,b)=> b.id - a.id)}  
        robots={robots}
        lots={lots}
        onStationsUpdated={fetchData} 
        onStationsChanged={fetchStations}
      />
    ),
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <div>Error</div>,
  }

  return stateList[loadingProgress]
}

export default StationList
