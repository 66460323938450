import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectRobotTelemetry, selectRobot } from 'store/selectors/robot'

import style from './RobotAdditionalInfo.module.css'

const cleaningEquipmentStateMapping: Record<number, string> =  {
  0: 'MANUAL',
  1: 'TRANSPORT',
  2: 'CLEANING', 
  10: 'EMERGENCY',
  255: 'ERROR',
};


const RobotAdditionalInfo = () => {
  const { t } = useTranslation()
  const telemetry = useSelector(selectRobotTelemetry)
  const robot = useSelector(selectRobot)
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)

  let equipmentStateString = "-"; // Default if no match

  if (telemetry?.cleaningEquipmentState !== undefined) {
    equipmentStateString = cleaningEquipmentStateMapping[telemetry.cleaningEquipmentState] || 'UNKNOWN'; // Handle unknown values
  }

  return (
    <div>
      <div className={style.additionalInfoHeader}>
        <div>{t('robot.additional_info')}</div>
        <div className={style.showMore} onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
          {showAdditionalInfo ? t('robot.additional_info_hide') : t('robot.additional_info_show')}
          <ArrowSvg className={style.icon} />
        </div>
      </div>
      {showAdditionalInfo && (
        <>
          <div className={style.row}>
            <div>{t('robot.cleaning_equipment_state')}</div>
            <div>{equipmentStateString}</div> {}
          </div>
          <div className={style.row}>
            <div>{t('robot.rtk_mode')}</div>
            <div>{telemetry?.rtkMode ? 'TRUE' : 'FALSE'}</div>
          </div>
          {/* <div className={style.row}>
            <div>{t('robot.developer_key')}</div>
            <div>{telemetry?.statusCode}</div>
          </div> */}
          <div className={style.row}>
            <div>{'Computing unit temperature'}</div>
            <div>
            {telemetry?.temperatureControlUnit !== undefined
                ? telemetry.temperatureControlUnit.toFixed(1) + '°C'
                : '-'}          
            </div>
          </div>
          <div className={style.row}>
          <div>{'Chassis temperature'}</div>
            <div>
            {telemetry?.temperatureChassis !== undefined
                ? telemetry.temperatureChassis.toFixed(1) + '°C'
                : '-'}          
            </div>
          </div>
          {telemetry && telemetry.temperatureLidar !== -273.15 && ( // absolute zero in celsius is not valid value
          <div className={style.row}>
          <div>{'Lidar temperature'}</div>
            <div>
            {telemetry?.temperatureLidar !== undefined
                ? telemetry.temperatureLidar.toFixed(1) + '°C'
                : '-'}          
            </div>
          </div>
          )} 
          <div className={style.row}>
          <div>{'External temperature'}</div>
            <div>
            {telemetry?.temperatureExternal !== undefined
                ? telemetry.temperatureExternal.toFixed(1) + '°C'
                : '-'}
            </div>
          </div>
          <div className={style.row}>
            <div>{'SIM card number'}</div>
            <div>{robot?.robotInfo?.sim_card_number ? robot.robotInfo.sim_card_number : '-'}</div>
          </div>
        </>
      )}
    </div>  
  )
}

export default RobotAdditionalInfo
