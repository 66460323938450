import { StationApi } from 'api/StationApi'
import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { format } from 'date-fns'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IStation, IRobotInfo, ILot, ICleaningZone } from 'utils/interfaces'
import { lotTypeMap}  from 'utils/lotInterfaces'
import RenameRecordedRouteModal from './RenameRecordedRouteModal/RenameRecordedRouteModal'

import style from './RecordedRouteItem.module.css'
import { MainApi } from 'api/MainApi'

const RecordedRouteItem = ({
  zone,
  index,
  highlighted,
  onZoneChanged,
  onClicked,
}: {
  zone: ICleaningZone
  index: number
  highlighted: boolean
  onZoneChanged: () => void
  onClicked: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const zoomZone = (zone_name: string) => {
    onClicked()
  }
  const [deleteZoneDialog, setDeleteZoneDialog] = useState(false)
  const [renameZoneDialog, setRenameZoneDialog] = useState(false)

  const deleteZone = async () => {
    try {
      await MainApi.deleteRouteRecordedZone(zone.properties.name)
      setDeleteZoneDialog(false)
      onZoneChanged()
    } catch (error) {
    console.log('Error deleting the recorded route and zone:')
    }
  }

  return (
    <>
      <div
        className={highlighted ? clsx(style.tableRow, style.highlighted) : style.tableRow}
        onClick={() => zoomZone(zone.properties.name)}
        key={zone.properties.zone_id}
      >
        <span className={style.tableCol}>{index}</span>
        <span className={style.tableCol}>{zone.properties.name}</span>
        <span className={style.tableCol}>{zone.properties.area ? 
          <span>
           {(zone.properties.area).toFixed(2)}m<sup>2</sup>
          </span>: ""}
        </span>
        <span className={style.tableCol}>{zone.properties.length ? `${zone.properties.length.toFixed(2)}m` : ""}</span>
        <span className={style.tableCol}>{format(new Date(zone.properties.createdAt), 'dd.MM.yyyy HH:mm')}</span>
        <div className={style.zoneItemColSmall}>
          <ActionsMenu>
            <ActionsMenuItem onClick={() => setRenameZoneDialog(true)} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span className={style.menuItemText} data-test-id={getTestId('edit-title')}>
                {t('common.rename')}
              </span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={() => setDeleteZoneDialog(true)} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        </div>
      </div>
      <ConfirmDialog
        title={t('view_zones.delete_question', { filename: zone.properties.name })}
        isOpen={deleteZoneDialog}
        onClose={() => setDeleteZoneDialog(false)}
        onConfirmClick={deleteZone}
        confirmText={t('common.delete')}
        danger={true}
      />
      <RenameRecordedRouteModal
        isOpen={renameZoneDialog}
        routeZoneName={zone.properties.name}
        onSave={onZoneChanged}
        onClose={() => setRenameZoneDialog(false)}
      />
    </>
  )
}

export default RecordedRouteItem
