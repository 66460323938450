import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { useSelector } from 'react-redux'
import { selectRobot} from 'store/selectors/robot'
import { selectRobotActiveMode } from 'store/selectors/robot'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { ROBOT_MODES } from 'utils/constants'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { ReactComponent as ChargingSvg } from 'images/icons/charging.svg'
import { ReactComponent as ParkingSvg } from 'images/icons/parking.svg'
import { ReactComponent as WashingSvg } from 'images/icons/washing.svg'
import { ReactComponent as MappingSvg } from 'images/icons/mapping.svg'
import { ReactComponent as RoutingSvg } from 'images/icons/routing.svg'
import { useState } from 'react'

import style from './Service.module.css'

const Service = () => {
  const activeMode = useSelector(selectRobotActiveMode)
  const robot = useSelector(selectRobot)
  const [confirmStartMappingDialog, setConfirmStartMappingDialog] = useState(false)
  const [confirmStartRoutingDialog, setConfirmStartRoutingDialog] = useState(false)
  const [confirmCalibParkingDialog, setConfirmCalibParkingDialog] = useState(false)
  const [confirmCalibChargingDialog, setConfirmCalibChargingDialog] = useState(false)
  const [confirmCalibWashingDialog, setConfirmCalibWashingDialog] = useState(false)

  const modeInProgress = activeMode !== ROBOT_MODES.MODE_NOT_SET && activeMode !== ROBOT_MODES.MODE_MANUAL
  const modeInParking = activeMode == ROBOT_MODES.MODE_PARKING
  const modeInCharging = activeMode == ROBOT_MODES.MODE_CHARGING
  const modeInWashing = activeMode == ROBOT_MODES.MODE_WASHING
  const modeInMapping = activeMode == ROBOT_MODES.MODE_MAPPING
  const modeInRouteRecording = activeMode == ROBOT_MODES.MODE_ROUTE_RECORDING
  const modeInCalibParking = activeMode == ROBOT_MODES.MODE_CALIBRATION_PARKING
  const modeInCalibCharging = activeMode == ROBOT_MODES.MODE_CALIBRATION_CHARGING
  const modeInCalibWashing = activeMode == ROBOT_MODES.MODE_CALIBRATION_WASHING

  const handleModeClick = (mode: boolean, setModeAction: Function, unsetModeAction: Function) => {
    if (robot.robotInfo?.id !== undefined) {
      if (!mode) {
        store.dispatch(setModeAction(robot.robotInfo.id));
      } else {
        store.dispatch(unsetModeAction(robot.robotInfo.id));
      }
    }
  };
  
  const onSetParkingModeClicked = () => handleModeClick(modeInParking, robotThunks.setRobotToParkingMode, robotThunks.setRobotNotSetMode);
  const onSetChargingModeClicked = () => handleModeClick(modeInCharging, robotThunks.setRobotToChargingMode, robotThunks.setRobotNotSetMode);
  const onSetWashingModeClicked = () => handleModeClick(modeInWashing, robotThunks.setRobotToWashingMode, robotThunks.setRobotNotSetMode);
  const onSetCalibParkingModeClicked = () => {
    handleModeClick(modeInCalibParking, robotThunks.setRobotToCalibrationParkingMode, robotThunks.setRobotNotSetMode);
    setConfirmCalibParkingDialog(false);
  };
  const onSetCalibChargingModeClicked = () => {
    handleModeClick(modeInCalibCharging, robotThunks.setRobotToCalibrationChargingMode, robotThunks.setRobotNotSetMode);
    setConfirmCalibChargingDialog(false);
  };
  const onSetCalibWashingModeClicked = () => {
    handleModeClick(modeInCalibWashing, robotThunks.setRobotToCalibrationWashingMode, robotThunks.setRobotNotSetMode);
    setConfirmCalibWashingDialog(false);
  }
  const onSetMappingModeClicked = () => {
    handleModeClick(modeInMapping, robotThunks.setRobotToMappingMode, robotThunks.setRobotNotSetMode);
    setConfirmStartMappingDialog(false);
  };
  const onSetRouteRecordingModeClicked = () => {
    handleModeClick(modeInRouteRecording, robotThunks.setRobotToRouteRecordingMode, robotThunks.setRobotNotSetMode);
    setConfirmStartRoutingDialog(false);
  }

  return (
    <div className={style.root}>

      <div className={style.tableHeaderRow}>
        <div className={style.centered}>
          Maintenance Tasks
        </div>
      </div>

      <div className={style.tableRow}>
        <div className={style.centered}>
          <ParkingSvg />
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Standart
            className={style.button}
            disabled={modeInProgress && !modeInParking}
            onClick={onSetParkingModeClicked}
          >
            {!modeInParking ? 'Start Parking' : 'Stop Parking'}
          </Button.Standart>
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Outlined
            className={style.buttonSmall}
            disabled={modeInProgress && !modeInCalibParking}
            onClick={() => modeInCalibParking ? onSetCalibParkingModeClicked() :  setConfirmCalibParkingDialog(true)}
          >
            {!modeInCalibParking ? 'Start calibration' : 'Stop Calibration'}
          </Button.Outlined>
        </div>
      </div>

      <div className={style.tableRow}>
        <div className={style.centered}>
          <ChargingSvg />
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Standart
            className={style.button}
            disabled={modeInProgress && !modeInCharging}
            onClick={onSetChargingModeClicked}
          >
            {!modeInCharging ? 'Start Charging' : 'Stop Charging'}
          </Button.Standart>
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Outlined
            className={style.buttonSmall}
            disabled={modeInProgress && !modeInCalibCharging}
            onClick={() =>  modeInCalibCharging ? onSetCalibChargingModeClicked() : setConfirmCalibChargingDialog(true)}
          >
            {!modeInCalibCharging ? 'Start calibration' : 'Stop Calibration'}
          </Button.Outlined>
        </div>
      </div>

      <div className={style.tableRow}>
        <div className={style.centered}>
          <WashingSvg />
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Standart
            className={style.button}
            disabled={modeInProgress && !modeInWashing}
            onClick={onSetWashingModeClicked}
          >
            {!modeInWashing ? 'Start Washing' : 'Stop Washing'}
          </Button.Standart>
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Outlined
            className={style.buttonSmall}
            disabled={modeInProgress && !modeInCalibWashing}
            onClick={() => modeInCalibWashing ? onSetCalibWashingModeClicked() : setConfirmCalibWashingDialog(true)}
          >
            {!modeInCalibWashing ? 'Start calibration' : 'Stop Calibration'}
          </Button.Outlined>
        </div>
      </div>

      <div className={style.tableHeaderRow}>
        <div className={style.centered}>
          Mapping Tasks
        </div>
      </div>

      <div className={style.tableRow}>
        <div className={style.centered}>
          <MappingSvg />
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Standart
            className={style.button}
            disabled={modeInProgress && !modeInMapping}
            onClick={() => modeInMapping ? onSetMappingModeClicked() : setConfirmStartMappingDialog(true)}
          >
            {!modeInMapping ? 'Start Map Recording' : 'Stop Map Recording'}
          </Button.Standart>
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          {modeInMapping ? 'Mapping in progress...' : ''}
        </div>
      </div>

      <div className={style.tableRow}>
        <div className={style.centered}>
          <RoutingSvg />
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          <Button.Standart
            className={style.button}
            disabled={modeInProgress && !modeInRouteRecording}
            onClick={() => modeInRouteRecording ? onSetRouteRecordingModeClicked() : setConfirmStartRoutingDialog(true)}
          >
          {!modeInRouteRecording ? 'Start Route Recording' : 'Stop Route Recording'}
          </Button.Standart>
        </div>
        <div className={clsx(style.chip, style.centered, style.locked)}>
          {modeInRouteRecording ? 'Route recording in progress...' : ''}
        </div>
      </div>

      <ConfirmDialog
        title={'Start map recording?'}
        isOpen={confirmStartMappingDialog}
        onClose={() =>  setConfirmStartMappingDialog(false)}
        onConfirmClick={onSetMappingModeClicked}
        confirmText={'Confirm'}
        danger={false}
      />

      <ConfirmDialog
        title={'Start route recording?'}
        isOpen={confirmStartRoutingDialog}
        onClose={() =>  setConfirmStartRoutingDialog(false)}
        onConfirmClick={onSetRouteRecordingModeClicked}
        confirmText={'Confirm'}
        danger={false}
      />

      <ConfirmDialog
        title={'Start the parking lot position calibration?'}
        isOpen={confirmCalibParkingDialog}
        onClose={() =>  setConfirmCalibParkingDialog(false)}
        onConfirmClick={onSetCalibParkingModeClicked}
        confirmText={'Confirm'}
        danger={true}
      />

      <ConfirmDialog
        title={'Start the charging lot position calibration?'}
        isOpen={confirmCalibChargingDialog}
        onClose={() =>  setConfirmCalibChargingDialog(false)}
        onConfirmClick={onSetCalibChargingModeClicked}
        confirmText={'Confirm'}
        danger={true}
      />

      <ConfirmDialog
        title={'Start the washing lot position calibration?'}
        isOpen={confirmCalibWashingDialog}
        onClose={() =>  setConfirmCalibWashingDialog(false)}
        onConfirmClick={onSetCalibWashingModeClicked}
        confirmText={'Confirm'}
        danger={true}
      />

    </div>
  )
}

export default Service
