import React from 'react';
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectLots} from 'store/selectors/station'
import { lotTypeMap, ILotEvent, statusTypeMap } from 'utils/lotInterfaces'
import style from './EventsLog.module.css'
import { IRobotInfo } from 'utils/interfaces';
import { format } from 'date-fns'
import { selectStation } from 'store/selectors/station'

interface EventsLogProps {
  robots: IRobotInfo[];
}

const EventsLog: React.FC<EventsLogProps> = ({ robots }) => {
  const lots = useSelector(selectLots)
  const [events, setEvents] = React.useState<ILotEvent[]>([])
  const station = useSelector(selectStation);

  useEffect(() => {
    const fetchEvents = async () => {
      let events: ILotEvent[] = [];
      if (lots) {
        events = lots.filter(lot => lot.robot_station === station.id)
                     .flatMap(lot => lot.state_history?.map(state => ({
            id: state.id,
            state_status: state.state_status,
            error_code: state.error_code,
            error_msg: state.error_msg,
            robot_sn: state.robot_sn,
            timestamp: state.timestamp,
            lot_id: lot.lot_id,
            type: lot.type
          })) || []
        );
      }
      // Sort events by timestamp in descending order
      events.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

      setEvents(events);
      console.log('Events:', events);
    }
    fetchEvents()
  }, [lots])

  const getRobotName = (robot_sn: number) => {
    const defaultRobotName = 'Robot with SN: ' + robot_sn;
    if (!robots) return defaultRobotName;
    if (robot_sn === 0) return '';
    const foundRobot = robots.find(robot => robot.serialNumber === robot_sn);
    if (foundRobot) return foundRobot.name;
    else return defaultRobotName;
  }

  return (
    <div className={style.root}>
      <div className={style.table}>
        <table>
          <thead>
            <tr className={style.tableRow}>
              <th>Lot SN</th>
              <th>Type</th>
              <th>Robot Name</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {[...events]
              .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
              .map(event => (
              <tr key={event.id} className={style.tableRow}>
                <td>{event.lot_id}</td>
                <td>{lotTypeMap[event.type]}</td>
                <td>{getRobotName(event.robot_sn)}</td>
                <td>{statusTypeMap[event.state_status]}</td>
                <td>
                  <div>
                    <div>
                      {event.timestamp ? format(new Date(event.timestamp), 'dd LLLL yyyy') : '-'}
                    </div>
                    <div className={style.percents}>
                      {event.timestamp ? format(new Date(event.timestamp), 'HH:mm:ss') : '-'}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EventsLog
