import clsx from 'clsx'
import { useMemo } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectMissionFinishPoint, selectMissionStartPoint } from 'store/selectors/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { ICleaningZone, IRestrictZone } from 'utils/interfaces'

import MissionPointTransferItem from './TransferItems/MissionPointTransferItem'
import StartFinishPointItem from './TransferItems/StartFinishPointItem'
import TransferRouteItem from './TransferItems/TransferRouteItem'
import CleaningZoneItem from './ZoneItem/CleaningZoneItem'
import RestrictedZoneItem from './ZoneItem/RestrictedZoneItem'

import style from './ViewZonesMode.module.css'

type ViewZonesModeProps = {
  cleaningZones: Array<ICleaningZone>
  restrictZones: Array<IRestrictZone>
  setCalculationMode?: (mode: 'all' | 'single' | undefined) => void
  hideActions?: boolean
  hideCleaningType?: boolean
}

const ViewZonesMode = (props: ViewZonesModeProps) => {
  const { t } = useTranslation()
  const missionStartPoint = useSelector(selectMissionStartPoint)
  const missionFinishPoint = useSelector(selectMissionFinishPoint)

  const cleaningZonesList = useMemo(() => {
    return (
      <>
        {props.cleaningZones.map((zone, index) => (
          <div key={zone.properties.zone_id}>
            {index !== 0 && (
              <TransferRouteItem
                type={TRANSFER_TYPE.MIDDLE}
                zoneIdToTransfer={zone.properties.zone_id}
                hideActions={props.hideActions}
                hideCleaningType={props.hideCleaningType}
              />
            )}
            <CleaningZoneItem 
              zone={zone} 
              number={index + 1} 
              hideActions={props.hideActions} 
              hideCleaningType={props.hideCleaningType}
              setCalculationMode={props.setCalculationMode || (() => {})} 
            />
          </div>
        ))}
      </>
    )
  }, [props.cleaningZones, props.hideActions, props.hideCleaningType])

  return (
    <div className={style.tableWrapper}>
      <div className={props.hideCleaningType ? style.tableHeaderShort : style.tableHeader}>
        <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.zone_number')}</div>
        <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.type')}</div>
        <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.name')}</div>
        {!props.hideCleaningType && (<div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.cleaning_type')}</div>)}
        <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.route_status')}</div>
      </div>

      {props.cleaningZones.length || props.restrictZones.length || missionStartPoint || missionFinishPoint ? (
        <div className={style.table}>
          {missionStartPoint && (
            <StartFinishPointItem
              type={TRANSFER_TYPE.START}
              missionPoint={missionStartPoint}
              hideActions={props.hideActions}
              hideCleaningType={props.hideCleaningType}
            />
          )}
          <MissionPointTransferItem type={TRANSFER_TYPE.START} hideActions={props.hideActions} hideCleaningType={props.hideCleaningType} />
          {cleaningZonesList}
          <MissionPointTransferItem type={TRANSFER_TYPE.FINISH} hideActions={props.hideActions} hideCleaningType={props.hideCleaningType} />
          {missionFinishPoint && (
            <StartFinishPointItem
              type={TRANSFER_TYPE.FINISH}
              missionPoint={missionFinishPoint}
              hideActions={props.hideActions}
              hideCleaningType={props.hideCleaningType}
            />
          )}

          {props.restrictZones.map((zone, index) => (
            <RestrictedZoneItem
              key={index}
              zone={zone as IRestrictZone}
              number={index + props.cleaningZones.length + 1}
              hideActions={props.hideActions}
              hideCleaningType={props.hideCleaningType}
            />
          ))}
        </div>
      ) : (
        <div className={style.emptyWrapper}>
          <div className={style.emptyTitle}>{t('view_zones.no_zones_title')}</div>
          <div className={style.emptyDescription}>{t('view_zones.no_zones_description')}</div>
        </div>
      )}
    </div>
  )
}

export default ViewZonesMode
