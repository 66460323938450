import Fade from '@mui/material/Fade'
import clsx from 'clsx'
import { ReactComponent as DropdownIcon } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import getTestId from 'utils/getTestId'

import { StyledDropdown } from './StyledDropdown'

import style1 from './Dropdown.module.css'
import style2 from './SmallDropdown.module.css'

export interface IOption {
  value: string | number
  text: string | number
}

export interface IPropsDropdown {
  options: IOption[]
  currentOption?: IOption
  placeholder?: string
  disabled?: boolean
  onClickOption: (option: IOption) => void
  dataTestId?: string
  className?: string
  description?: string
  useSmallDropdownStyle?: boolean
}

const Dropdown: React.FC<IPropsDropdown> = ({ 
  options,
  onClickOption = () => {},
  placeholder = '',
  currentOption = null,
  disabled = false,
  dataTestId = 'dropdown',
  description,
  className,
  useSmallDropdownStyle = false,
}) => {
  const [open, setOpen] = useState(false)
  const id = `${Math.random()}-dropdown`
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const style = useSmallDropdownStyle ? style2 : style1

  const toggleOpen = () => {
    if (!disabled) {
      setOpen(prev => !prev)
    }
  }

  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setAnchorEl(open ? null : event.currentTarget)
      toggleOpen()
    }
  }

  const handleClickOption = (option: IOption) => {
    if (!disabled) {
      toggleOpen()
      onClickOption(option)
    }
  }

  return (
    <>
      <div className={className}>
        <div className={clsx('text-14-400', style.subtitle)}>{description}</div>
        <div
          className={clsx(style.placeholder, { [style.placeholderDisabled]: disabled })}
          onClick={handleClickDropdown}
          aria-controls={open ? id : undefined}
          data-test-id={getTestId(`${dataTestId}-placeholder`)}
        >
          <div className={style.placeholderText} data-test-id={getTestId(`${dataTestId}-placeholderText`)}>
            {currentOption?.text ? currentOption.text : placeholder}
          </div>
          <div
            className={clsx(style.placeholderIcon, { [style.placeholderIconOpen]: open })}
            data-test-id={getTestId(`${dataTestId}-icon`)}
          >
            <DropdownIcon />
          </div>
        </div>
      </div>
      <StyledDropdown
        MenuListProps={{ 'aria-labelledby': id }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Fade}
        data-test-id={getTestId(`${dataTestId}-styledDropdown`)}
      >
        {options.map((option, index) => {
          const isActive = currentOption?.value === option.value
          return (
            <div
              key={index}
              onClick={() => handleClickOption(option)}
              className={clsx(style.dropdownItem, { [style.dropdownItemActive]: isActive })}
              data-value={option.value}
              style={{
                ...(anchorEl?.clientWidth && { width: `${anchorEl.clientWidth}px` }),
              }}
              data-test-id={getTestId(`${dataTestId}-${option.value}`)}
            >
              <span style={{ padding: '10px 0' }}>{option.text}</span>
            </div>
          )
        })}
      </StyledDropdown>
    </>
  )
}

export default Dropdown
