import { FeatureCollection, Point } from 'geojson'
import StationOnMapImage from 'images/station_on_map.png'
import { useCallback, useEffect, useMemo } from 'react'
import { Layer, Source, SymbolLayer } from 'react-map-gl'

const layerStationStyle: SymbolLayer = {
  id: 'station-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'station',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': 1,
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 1,
  },
}

export default function StationsOnMap({
  map,
  stations,
  onPointChanged,
}: {
  map: mapboxgl.Map | undefined
  stations: Point[]
  onPointChanged?: (point: Point) => void
}) {
  const stationPointData = useMemo<FeatureCollection<Point>>(() => {
    return {
      type: 'FeatureCollection',
      features: stations?.map(stationPoint => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: stationPoint.coordinates,
          },
          properties: {},
        }
      }),
    }
  }, [stations])

  useEffect(() => {
    loadStationImage(map)
    map?.on('style.load', () => loadStationImage(map))
  }, [map])

  const loadStationImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('station')) {
      return
    }
    map?.loadImage(StationOnMapImage, (error, image) => {
      if (error || !image) {
        console.log('error loading image')
        return
      }
      if (!map?.hasImage('station')) {
        map?.addImage('station', image)
      }
    })
  }

  const onMapClicked = useCallback(
    (e: any) => {
      onPointChanged && onPointChanged({ type: 'Point', coordinates: [e.lngLat.lng, e.lngLat.lat] })
    },
    [onPointChanged]
  )

  useEffect(() => {
    map?.on('click', onMapClicked)
    return () => {
      map?.off('click', onMapClicked)
    }
  }, [map, onMapClicked])

  return (
    <Source id="station" type="geojson" data={stationPointData}>
      <Layer {...layerStationStyle} />
    </Source>
  )
}
