import { MainApi } from 'api/MainApi'
import { MissionActions } from 'store/actions/mission'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const createLayer = (missionId: number, name: string, file: File) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.WORK))
      let layer = getState().mission.geoTiffLayer.layer
      if (!layer) {
        layer = await MainApi.createLayer(missionId, name)
      }
      await MainApi.uploadLayerFile(layer.id, file)
      dispatch(MissionActions.setGeoTiffLayer(layer))
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.ERROR))
      console.log('Error creating layer')
    }
  }
}

const createLayerFromServer = (missionId: number, filename: string) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.WORK));
      // Create the layer first
      const layer = await MainApi.createLayer(missionId, filename);
      // Attach the file to the newly created layer using its ID
      await MainApi.uploadLayerFileByName(layer.id, filename); 

      dispatch(MissionActions.setGeoTiffLayer(layer)); 
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.SUCCESS));
    } catch (error) {
      dispatch(MissionActions.setGeoTiffLayerUploadingProgress(PROGRESS_TYPES.ERROR));
      console.error('Error creating layer:', error); 
    }
  }
}

const deleteLayer = (layerId: number) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(MissionActions.setGeoTiffLayerDeletingProgress(PROGRESS_TYPES.WORK))
      await MainApi.deleteLayer(layerId)
      dispatch(MissionActions.setGeoTiffLayer(undefined))
      dispatch(MissionActions.setGeoTiffLayerDeletingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(MissionActions.setGeoTiffLayerDeletingProgress(PROGRESS_TYPES.ERROR))
      console.log('Error deleting layer')
    }
  }
}

export const layerThunks = {
  createLayer,
  createLayerFromServer,
  deleteLayer,
}
