import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { ReactComponent as MissionSvg } from 'images/icons/mission_play.svg'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { selectRobotActiveMode, selectRobotJob } from 'store/selectors/robot'
import { PATH, ROBOT_MODES } from 'utils/constants'

import style from './RobotMissionMainStatus.module.css'

enum STATES {
  SELECT_MISSION,
  MISSION_READY_TO_START,
  UPLOADING_MISSION_ERROR,
  UPLOADING,
  CALCULATING_SCENARIO,
  TURN_ON_ROBOT,
  MISSION_IS_ACTIVE,
  MISSION_IS_NOT_ACTIVE,
}

const RobotMissionMainStatus = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const job = useSelector(selectRobotJob)
  const activeMode = useSelector(selectRobotActiveMode)

  const state = useMemo(() => {
    if (activeMode === null || activeMode === undefined) {
      return STATES.TURN_ON_ROBOT
    }

    if (activeMode !== ROBOT_MODES.MODE_NOT_SET) {
      return STATES.MISSION_IS_ACTIVE
    }

    if (activeMode === ROBOT_MODES.MODE_NOT_SET) {
      return STATES.MISSION_IS_NOT_ACTIVE
    }

    return STATES.TURN_ON_ROBOT

  }, [activeMode])

  const styles = {
    [`${STATES.TURN_ON_ROBOT}`]: style.idle,
    [`${STATES.MISSION_IS_ACTIVE}`]: style.idle,
    [`${STATES.MISSION_IS_NOT_ACTIVE}`]: style.idle,
  }

  const messages = {
    [STATES.TURN_ON_ROBOT]: t('robot.turn_on'),
    [STATES.MISSION_IS_ACTIVE]: activeMode == ROBOT_MODES.MODE_MAPPING ? "Mapping in progress" :
                                activeMode == ROBOT_MODES.MODE_ROUTE_RECORDING ? "Route recording in progress" :
                                activeMode == ROBOT_MODES.MODE_CHARGING ? "Charging in progress" :
                                activeMode == ROBOT_MODES.MODE_WASHING  ? "Washing in progress" :
                                activeMode == ROBOT_MODES.MODE_PARKING  ? "The robot is parked" :
                                activeMode == ROBOT_MODES.MODE_CALIBRATION_CHARGING  || 
                                  activeMode == ROBOT_MODES.MODE_CALIBRATION_WASHING ||
                                  activeMode == ROBOT_MODES.MODE_CALIBRATION_PARKING ? "Calibration in progress" :                  
                                activeMode == ROBOT_MODES.MODE_MANUAL ? "The robot in manual mode" :                        
                                activeMode == ROBOT_MODES.MODE_AUTOMATIC ? t('robot.active') :
                                "Unknown mode",
    [STATES.MISSION_IS_NOT_ACTIVE]: t('robot.not_active'),
  }

  const serviceMode = activeMode == ROBOT_MODES.MODE_MAPPING  || activeMode == ROBOT_MODES.MODE_ROUTE_RECORDING      || 
                      activeMode == ROBOT_MODES.MODE_WASHING  || activeMode == ROBOT_MODES.MODE_CALIBRATION_CHARGING ||
                      activeMode == ROBOT_MODES.MODE_CHARGING || activeMode == ROBOT_MODES.MODE_CALIBRATION_WASHING  ||
                      activeMode == ROBOT_MODES.MODE_PARKING  || activeMode == ROBOT_MODES.MODE_CALIBRATION_PARKING

  const onGoToMissionClick = () => {
    if (job) {
      const path = generatePath(PATH.MISSION_CONTROL, { id: String(job.id) })
      navigate(path)
    }
  }

  return (
    <div>
      <div className={clsx(style.main, styles[state])}>
        <MissionSvg />
        <div className={style.message} dangerouslySetInnerHTML={{ __html: messages[state] }} />
        {(
          <Button.Standart className={style.buttonWide} onClick={onGoToMissionClick} disabled={serviceMode || activeMode == ROBOT_MODES.MODE_NOT_SET}>
            {t('robot.button_go_to_mission')}
          </Button.Standart>
        )}
      </div>
    </div>
  )
}

export default RobotMissionMainStatus
