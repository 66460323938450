import clsx from 'clsx'

import style from './CenterOnStationButton.module.css'

const CenterOnStationButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={style.root} onClick={onClick}>
      <button className={style.button}>
        <span className={clsx('mapboxgl-ctrl-icon', style.icon)} />
      </button>
    </div>
  )
}

export default CenterOnStationButton
