import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { ReactComponent as ZoneSvg } from 'images/icons/cleaning_zone_small.svg'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as ZoneRestrSvg } from 'images/icons/draw_zone_restricted.svg'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MissionActions } from 'store/actions/mission'
import { selectHighlightedFeatureIdInList } from 'store/selectors/mission'
import store from 'store/store'
import { missionThunks } from 'store/thunks/mission'
import { MISSION_ZONE_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IRestrictZone } from 'utils/interfaces'

import style from './ZoneItem.module.css'

const RestrictedZoneItem = ({
  zone,
  number,
  hideActions,
  hideCleaningType,
}: {
  zone: IRestrictZone
  number: number
  hideActions?: boolean
  hideCleaningType?: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const highlightedFeatureIdInList = useSelector(selectHighlightedFeatureIdInList)
  const highlighted = useMemo(() => {
    return highlightedFeatureIdInList === zone.properties.zone_id || highlightedFeatureIdInList === zone.id
  }, [highlightedFeatureIdInList, zone])
  const zoneItemStyle = hideCleaningType ? style.zoneItemShort : style.zoneItem

  const onZoneClicked = () => {
    dispatch(MissionActions.setHighlightedFeatureInList(zone.properties.zone_id))
    dispatch(MissionActions.setHighlightedFeature(zone))
  }

  const onEditZoneClicked = () => {
    store.dispatch(missionThunks.setEditingZone(zone))
  }

  const onRemoveZoneClicked = () => {
    if (zone.properties?.zone_id) {
      store.dispatch(missionThunks.deleteRestrictZone(zone))
    }
  }

  return (
    <div className={highlighted ? clsx(zoneItemStyle, style.highlighted) : zoneItemStyle} onClick={onZoneClicked}>
      <div className={style.zoneItemColSmall}>#{number}</div>
      <div className={style.zoneItemColSmall}>
        {zone.properties?.zone_type === MISSION_ZONE_TYPES.RESTRICT ? (
          <ZoneRestrSvg className={style.icon} />
        ) : (
          <ZoneSvg className={style.icon} />
        )}
      </div>
      <div className={style.zoneItemCol}>{zone.properties?.name}</div>
      <div className={style.szoneItemCol}></div>
      { !hideCleaningType && (<div className={style.zoneItemCol}></div>)}
      <div className={style.zoneItemColSmall}>
        {!hideActions && (
          <ActionsMenu>
            <ActionsMenuItem onClick={onEditZoneClicked} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span data-test-id={getTestId('edit-title')}>{t('common.edit')}</span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={onRemoveZoneClicked} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        )}
      </div>
    </div>
  )
}

export default RestrictedZoneItem
