import { MainApi } from 'api/MainApi';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/Loader/Loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROGRESS_TYPES, FILES_LOADING_TYPE } from 'utils/constants';
import type { FileData } from 'api/schema/models/FileData';

import FilesList from './FilesList/FilesList';

import style from './SelectFileModal.module.css';

interface IDialogProps {
    isOpen: boolean;
    mode: FILES_LOADING_TYPE;
    onConfirmClick: (filename: string) => void; 
    onClose: () => void;
}

const SelectFileModal = ({ isOpen, mode, onConfirmClick, onClose }: React.PropsWithChildren<IDialogProps>) => {
    const { t } = useTranslation();
    const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE);
    const [files, setFiles] = useState<FileData[]>([]);
    const [selectedFile, setSelectedFile] = useState<FileData | undefined>(undefined);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingProgress(PROGRESS_TYPES.WORK);
                switch (mode) {
                    case FILES_LOADING_TYPE.MAP:
                        const allFiles = await MainApi.fetchGeotiffFiles();
                        setFiles(allFiles);
                        break;
                    case FILES_LOADING_TYPE.ROUTE:
                        const fetchedFiles = await MainApi.fetchRouteRecordedFiles();
                        setFiles(fetchedFiles);
                        break;
                    default:
                        console.error("Invalid file loading mode:", mode);
                        throw new Error("Invalid mode");
                        break;
                }
                setLoadingProgress(PROGRESS_TYPES.SUCCESS);
            } catch (error) {
                setLoadingProgress(PROGRESS_TYPES.ERROR);
                console.error("Error fetching files:", error);
                // Add UI error handling here (e.g., a toast message).
            }
        };

        if (isOpen) {
            fetchData(); // Call fetchData only when the modal opens
        } else {
            // Reset state when closing if needed:
            setSelectedFile(undefined);  // Unselect if you want.
            setLoadingProgress(PROGRESS_TYPES.IDLE);  // Optional
        }
    }, [isOpen]);  // Only re-run the effect if isOpen changes

    const handleFileSelect = (file: FileData) => {
        setSelectedFile(file);
    };

    const handleConfirmClick = () => {
        if (selectedFile) {
            onConfirmClick(selectedFile.name); 
            onClose(); 
        }
    };

    return (
        <ConfirmDialog
            isOpen={isOpen}
            className={style.root}
            onClose={onClose}
            onConfirmClick={handleConfirmClick} 
            title="Select a file"
            confirmText="Select"
            confirmButtonDisabled={!selectedFile}
        >
            <div className={style.content}>
                {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
                {loadingProgress === PROGRESS_TYPES.SUCCESS && files.length > 0 ? (
                    <FilesList files={files} onSelect={handleFileSelect} selectedFile={selectedFile} />
                ) : loadingProgress === PROGRESS_TYPES.SUCCESS ? (
                    <p>No files found.</p>
                ) : null /* Don't render anything if not loaded or successful */}
            </div>
        </ConfirmDialog>
    );
};


export default SelectFileModal;
