import { Button } from 'components/Button/Button'
import Title from 'components/Title/Title'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'

import style from './AddMissionModal.module.css'

interface IDialogProps {
  isOpen: boolean
  robotTypes: string[]
  onClose: () => void
  onSelect: (robotType: string) => void
}

const AddMissionModal = ({ isOpen, robotTypes, onClose, onSelect }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()

  const onCancelClicked = () => {
      onClose()
  }

  const onSelectClicked = async () => {
    onSelect(robotType)
  }

  const robotTypeOptions: IOption[] = Object.values(robotTypes).filter((value) => typeof value === 'string').map(type => ({
    text: type,
    value: type,
  }));

  const [robotType, setRobotType] = useState(robotTypes[0]) 

  const [robotTypeOption, setRobotTypeOption] = useState(
    robotType ? { text: robotType, value: robotType } : robotTypeOptions[0]
  )

  const onTypeSelected = (e: IOption) => {
    setRobotTypeOption(e);
    setRobotType(e.value as string);
  };  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelClicked}
      className={style.root}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(27, 43, 72, 0.6)',
        },
      }}
      ariaHideApp={false}
    >
      <div className={style.modal}>
        <div className={style.head}>
          <Title text="Select a robot's type" className={style.title} />
        </div>
        <div className={style.row}>
          <Dropdown
            className={style.textfield}
            currentOption={robotTypeOption}
            options={robotTypeOptions}
            onClickOption={onTypeSelected}
          />
        </div>
        <div className={style.footer}>
          <Button.Outlined className={style.btn} onClick={onCancelClicked}>
            {t('mission_settings.label_cancel')}
          </Button.Outlined>
          <Button.Standart
            className={style.btn}
            onClick={onSelectClicked}
          >
            Select
          </Button.Standart>
        </div>
      </div>
    </Modal>
  )
}

export default AddMissionModal
