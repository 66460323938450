import clsx from 'clsx'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { PATH } from 'utils/constants'
import { RobotRead } from 'api/schema'

import style from './RobotList.module.css'

const RobotModalItem = ({
  robot,
  selected,
  onSelect,
}: {
  robot: RobotRead
  selected: boolean
  onSelect: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div className={selected ? clsx(style.tableItem, style.selected) : style.tableItem} onClick={onSelect}>
      <span className={style.robotName}>{robot.serial_number}
      </span>
      <span className={style.robotName}>{robot.name} </span>
      <span className={style.robotName}>{robot.station_id} </span>
      <a className={style.link} href={`${PATH.ROBOT}${robot.id}`} target="_blank" rel="noreferrer">
        {t('common.show')}
      </a>
    </div>
  )
}

const RobotsList = ({
  robots,
  selectedRobot,
  onSelect,
}: {
  robots: RobotRead[]
  selectedRobot: RobotRead | undefined
  onSelect: (robot: RobotRead) => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={style.tableHeader}>
        <span>{"Serial number"}</span>
        <span>{"Name"}</span>
        <span>{"Station ID"}</span>
        <span>{"Link"}</span>
      </div>
      <div className={style.table}>
        {robots.map(robot => (
          <RobotModalItem
            key={robot.id}
            robot={robot}
            selected={robot === selectedRobot}
            onSelect={() => onSelect(robot)}
          />
        ))}
      </div>
    </>
  )
}

export default RobotsList
