import {
  CleaningZone,
  CleaningZoneRead,
  FileData,
  MainService,
  Mission,
  MissionPointTransferRouteRead,
  MissionRead,
  MissionReadList,
  MissionStatus,
  MissionXyzLayerRead,
  RestrictZone,
  TransferRouteRead,
  SchedulerSettings,
  MissionJob,
  MissionJobTypeEnum,
  CleaningTypeEnum,
} from 'api/schema/index'
import { LineString, Point, Polygon } from 'geojson'
import chunk from 'lodash.chunk'
import {
  DEFAULT_VIZUALIZATION_SETTINGS,
  GEOTIFF_LAYER_STATUSES,
  MISSION_CALCULATING_STATUSES,
  MISSION_ZONE_TYPES,
  TRANSFER_CALCULATING_STATUSES,
  TRANSFER_TYPE,
} from 'utils/constants'
import {
  ICleaningZone,
  IGeoTiffLayer,
  ILaunchPoint,
  IMission,
  IMissionInfo,
  IPoint,
  IRestrictZone,
  IRoute,
  ITransferRoute,
  IVisualizationSettings,
  IZone,
  IZoneTransferRoute,
} from 'utils/interfaces'
import { parseISO } from 'date-fns';

const normalizeCleaningZone = (zoneRaw: CleaningZoneRead | CleaningZone): ICleaningZone => {
  return {
    id: 'cleaning_' + zoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: (zoneRaw.geometry as Polygon).coordinates,
    },
    properties: {
      zone_type: MISSION_ZONE_TYPES.CLEANING,
      name: zoneRaw.name || '',
      description: zoneRaw.description || '',
      zone_id: zoneRaw.id,
      cleaning_type: zoneRaw.cleaning_type || 2,
      id_on_map: 'cleaning_' + zoneRaw.id,
      status: (zoneRaw as CleaningZoneRead).status
        ? ((zoneRaw as CleaningZoneRead).status as MISSION_CALCULATING_STATUSES)
        : MISSION_CALCULATING_STATUSES.NEW,
      readyForCalculation: (zoneRaw as CleaningZoneRead).is_ready_to_start_calculation
        ? (zoneRaw as CleaningZoneRead).is_ready_to_start_calculation
        : true,
      length: (zoneRaw as CleaningZoneRead).cleaning_route_geometry_length || undefined,
      area: (zoneRaw as CleaningZoneRead).geometry_area || undefined,
      executionTime: (zoneRaw as CleaningZoneRead).cleaning_route_execution_time || undefined,
      filename: (zoneRaw as CleaningZoneRead).filename || undefined,
      is_recorded: (zoneRaw as CleaningZoneRead).is_recorded || undefined,
      createdAt: parseISO((zoneRaw as CleaningZoneRead).created_at),
      updatedAt: parseISO((zoneRaw as CleaningZoneRead).updated_at),
    },
  }
}

const normalizeRestrictZone = (zoneRaw: RestrictZone): IRestrictZone => {
  return {
    id: 'restrict_' + zoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: (zoneRaw.geometry as Polygon).coordinates,
    },
    properties: {
      zone_type: MISSION_ZONE_TYPES.RESTRICT,
      name: zoneRaw.name || '',
      description: zoneRaw.description || '',
      zone_id: zoneRaw.id,
      id_on_map: 'restrict_' + zoneRaw.id,
    },
  }
}

const normalizeCleaningRoute = (cleaningZoneRaw: CleaningZoneRead): IRoute => {
  return {
    id: 'cleaning_route_' + cleaningZoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'MultiLineString',
      coordinates: cleaningZoneRaw.cleaning_route_geometry?.coordinates
        ? chunk(cleaningZoneRaw.cleaning_route_geometry.coordinates[0], 30000)
        : [],
    },
    properties: {
      zone_id: cleaningZoneRaw.id,
      id_on_map: 'cleaning_' + cleaningZoneRaw.id,
    },
  }
}

const normalizeTransferRoute = (transferRouteRaw: TransferRouteRead): IZoneTransferRoute => {
  return {
    id: 'transfer_route_' + transferRouteRaw.id,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.geometry?.coordinates || [],
    },
    userGeometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.user_geometry?.coordinates || transferRouteRaw.geometry?.coordinates || [],
    },
    properties: {
      id: transferRouteRaw.id,
      zone_id_from: transferRouteRaw.cleaning_zone_from_id,
      zone_id_to: transferRouteRaw.cleaning_zone_to_id,
      id_on_map: 'transfer_route_' + transferRouteRaw.id,
      type: TRANSFER_TYPE.MIDDLE,
      status: transferRouteRaw.status
        ? (transferRouteRaw.status as TRANSFER_CALCULATING_STATUSES)
        : TRANSFER_CALCULATING_STATUSES.NEW,
      length: transferRouteRaw.geometry_length || transferRouteRaw.user_geometry_length || undefined,
    },
  }
}

const normalizeRoute = (transferRouteRaw: MissionPointTransferRouteRead): ITransferRoute => {
  return {
    id: 'mission_route_' + transferRouteRaw.id,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.geometry?.coordinates || transferRouteRaw.user_geometry?.coordinates || [],
    },
    userGeometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.user_geometry?.coordinates || transferRouteRaw.geometry?.coordinates || [],
    },
    properties: {
      id: transferRouteRaw.id,
      type: transferRouteRaw.point_type === 'start' ? TRANSFER_TYPE.START : TRANSFER_TYPE.FINISH,
      status: transferRouteRaw.status
        ? (transferRouteRaw.status as TRANSFER_CALCULATING_STATUSES)
        : TRANSFER_CALCULATING_STATUSES.NEW,
      length: transferRouteRaw.geometry_length || transferRouteRaw.user_geometry_length || undefined,
    },
  }
}

const normalizeLaunchPoints = (cleaningZonesRaw: CleaningZoneRead[]): ILaunchPoint[] => {
  const result = cleaningZonesRaw.filter(zone => zone.starting_point).map(zone => normalizeLaunchPoint(zone))
  return result as ILaunchPoint[]
}

const normalizeLaunchPoint = (cleaningZoneRaw: CleaningZoneRead | CleaningZone): ILaunchPoint | undefined => {
  if (cleaningZoneRaw.starting_point?.coordinates) {
    return {
      id: 'launch_point_' + cleaningZoneRaw.id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: cleaningZoneRaw.starting_point?.coordinates,
      },
      properties: {
        zone_id: cleaningZoneRaw.id,
        id_on_map: 'cleaning_' + cleaningZoneRaw.id,
      },
    }
  }
}

const normalizeMissionInfo = (missionRaw: MissionRead | MissionReadList): IMissionInfo => {
    const isList = "results" in missionRaw;
    const mission = isList ? missionRaw.results[0] : missionRaw; 
    
  return {
    id: mission.id,
    name: mission.name || '',
    description: mission.description || '',
    createdAt: mission.created_at,
    updatedAt: mission.updated_at,
    status: mission.status as MISSION_CALCULATING_STATUSES,
    robot_type: mission.robot_type || '',
    transport_speed: mission.transport_speed || 0,
    cleaning_speed: mission.cleaning_speed || 0,
  }
}

const normalizeGeoTiffLayer = (layerRaw: MissionXyzLayerRead | undefined): IGeoTiffLayer | undefined => {
  if (!layerRaw) {
    return
  }
  const bbox: Polygon | undefined =
    layerRaw.bbox && layerRaw.bbox.coordinates
      ? {
          type: 'Polygon',
          coordinates: layerRaw.bbox.coordinates,
        }
      : undefined
  const geoTiffLayer = layerRaw
    ? {
        id: layerRaw.id,
        bbox,
        urlTiles: layerRaw.file_tiles || undefined,
        name: layerRaw.name || '',
        status: (layerRaw.status as GEOTIFF_LAYER_STATUSES) || GEOTIFF_LAYER_STATUSES.NEW,
      }
    : undefined
  return geoTiffLayer
}

const normalizePoint = (
  pointRaw?: { type?: 'Point'; coordinates?: Array<number> } | null,
  start: boolean = true
): IPoint | undefined => {
  if (pointRaw && pointRaw.coordinates) {
    const id = start ? 'mission_start_point' : 'mission_finish_point'
    return {
      id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: pointRaw?.coordinates,
      },
      properties: {
        id_on_map: id,
      },
    }
  }
}

let statusIsPending = false

export const MainApi = {
  fetchMissions: async (): Promise<IMissionInfo[]> => {
    const missions = await MainService.mainMissionList()
    if (!missions.results) {
      return []
    }
    const normalizedMissions = missions.results.map(missionRaw => normalizeMissionInfo(missionRaw))
    return normalizedMissions
  },
  createMission: async (name: string, robot_type: string, description: string): Promise<Mission> => {
    const mission = await MainService.mainMissionCreate({
      name,
      robot_type,
      description,
    })
    return mission
  },
  fetchMission: async (id: number): Promise<IMission> => {
    const missionRaw = await MainService.mainMissionRetrieve(id)
    const mission = normalizeMissionInfo(missionRaw)
    const status = missionRaw.status
      ? (missionRaw.status as MISSION_CALCULATING_STATUSES)
      : MISSION_CALCULATING_STATUSES.NEW
    const isReadyForCalculation = missionRaw.is_ready_to_start_calculation

    const cleaningZones = missionRaw.cleaning_zones.map(zoneRaw => normalizeCleaningZone(zoneRaw))
    const restrictZones = missionRaw.restrict_zones.map(zoneRaw => normalizeRestrictZone(zoneRaw))
    const cleaningRoutes = missionRaw.cleaning_zones
      .filter(zoneRaw => zoneRaw.cleaning_route_geometry)
      .map(zoneRaw => normalizeCleaningRoute(zoneRaw))
    const transferRoutes = missionRaw.transfer_routes.map(transferRouteRaw => normalizeTransferRoute(transferRouteRaw))
    const launchPoints = normalizeLaunchPoints(missionRaw.cleaning_zones)
    const geoTiffLayer = normalizeGeoTiffLayer(missionRaw.xyz_layers[0])
    const startingPoint = normalizePoint(missionRaw.starting_point, true)
    const finishingPoint = normalizePoint(missionRaw.finishing_point, false)
    const pointRoutes = missionRaw.points_transfer_routes.map(routeRaw => normalizeRoute(routeRaw))
    const visualizationSettings = { ...DEFAULT_VIZUALIZATION_SETTINGS, ...missionRaw.visualization_settings }
    return {
      status,
      mission,
      cleaningZones,
      restrictZones,
      cleaningRoutes,
      launchPoints,
      isReadyForCalculation,
      transferRoutes,
      geoTiffLayer,
      startingPoint,
      finishingPoint,
      pointRoutes,
      visualizationSettings,
    }
  },
  changeMissionName: async (id: number, name: string): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      name,
    })
    return missionRaw
  },

  changeMissionRobotType: async (id: number, robot_type: string): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionChangeRobotType(id, {
      robot_type,
    })
    return missionRaw
  },

  changeMissionSpeed: async (id: number, transport_speed: number, cleaning_speed: number): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionChangeSpeed(id, {
      transport_speed: transport_speed,
      cleaning_speed: cleaning_speed
    })
    return missionRaw
  },


  changeMissionVisualSettings: async (id: number, visualSettings: IVisualizationSettings): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      visualization_settings: visualSettings,
    })
    return missionRaw
  },
  changeMissionStartPoint: async (id: number, startingPoint: Point | null): Promise<IPoint | undefined> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      starting_point: startingPoint,
    })
    return normalizePoint(missionRaw.starting_point)
  },
  changeMissionFinishPoint: async (id: number, finishingPoint: Point | null): Promise<IPoint | undefined> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      finishing_point: finishingPoint,
    })
    return normalizePoint(missionRaw.finishing_point)
  },

  updateMissionJobSchedule: async(id: number, scheduler_settings: SchedulerSettings): Promise<MissionJob> => {
    const missionJobRaw = await MainService.mainMissionJobPartialUpdate(id, {
      scheduler_settings: scheduler_settings
    })
    return missionJobRaw
  },

  updateMissionJobType: async(id: number, type: MissionJobTypeEnum): Promise<MissionJob> => {
    const missionJobRaw = await MainService.mainMissionJobPartialUpdate(id, {
      job_type: type
    })
    return missionJobRaw
  },

  deleteMission: async (id: number): Promise<void> => {
    await MainService.mainMissionDestroy(id)
  },

  copyMission: async (id: number): Promise<void> => {
    await MainService.mainMissionCopy(id)
  },

  createRestrictZone: async (
    missionId: number,
    name: string,
    description: string,
    geometry: Polygon
  ): Promise<IZone> => {
    const zone = await MainService.mainRestrictZoneCreate({
      name,
      description,
      mission_id: missionId,
      geometry,
    })
    return normalizeRestrictZone(zone)
  },
  deleteRestrictZone: async (id: number): Promise<void> => {
    await MainService.mainRestrictZoneDestroy(id)
  },
  patchRestrictZone: async (
    zoneId: number,
    missionId: number,
    name: string,
    description: string,
    geometry?: Polygon
  ): Promise<IZone> => {
    const zone = await MainService.mainRestrictZonePartialUpdate(zoneId, {
      name,
      description,
      mission_id: missionId,
      geometry,
    })
    return normalizeRestrictZone(zone)
  },
  createCleaningZone: async (
    missionId: number,
    name: string,
    description: string,
    geometry: Polygon,
    filename: string | undefined,
    launchPointGeometry?: Point
  ): Promise<{ zone: ICleaningZone; launchPoint: ILaunchPoint | undefined }> => {
    const zone = await MainService.mainCleaningZoneCreate({
      name,
      description,
      mission_id: missionId,
      geometry,
      starting_point: launchPointGeometry,
      filename: filename
    })
    return { zone: normalizeCleaningZone(zone), launchPoint: normalizeLaunchPoint(zone) }
  },

  changeCleaningTypeCleaningZone: async (id: number, cleaning_type: number): Promise<CleaningZone> => {
    const zoneRaw = await MainService.mainCleaningZonePartialUpdate(id, {
      cleaning_type,
    })
    return zoneRaw
  },

  getPolygonFromFile: async (filename: string): Promise<Polygon> => {
    const polygonRaw = await MainService.mainGetPolygonFromFile(
      filename
    )
    return polygonRaw
  },

  patchCleaningZone: async (
    zoneId: number,
    missionId: number,
    name: string,
    description: string,
    filename: string | undefined,
    geometry?: Polygon,
    launchPoint?: Point | null
  ): Promise<{ zone: IZone; launchPoint: ILaunchPoint | undefined }> => {
    const zone = await MainService.mainCleaningZonePartialUpdate(zoneId, {
      name,
      description,
      filename,
      mission_id: missionId,
      geometry,
      starting_point: launchPoint,
    })
    return { zone: normalizeCleaningZone(zone), launchPoint: normalizeLaunchPoint(zone) }
  },
  fetchCleaningZone: async (zoneId: number) => {
    const zone = await MainService.mainCleaningZoneRetrieve(zoneId)
    return { zone: normalizeCleaningZone(zone), route: normalizeCleaningRoute(zone) }
  },
  deleteCleaningZone: async (id: number): Promise<void> => {
    await MainService.mainCleaningZoneDestroy(id)
  },
  startCalculatingRoutes: async (missionId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainMissionStartCalculationCreate(missionId)
  },
  fetchCalculationStatus: async (missionId: number): Promise<MissionStatus | undefined> => {
    if (statusIsPending) {
      return
    }
    statusIsPending = true
    const status = await MainService.mainMissionGetStatusRetrieve(missionId)
    if (!statusIsPending) {
      return
    }
    statusIsPending = false
    return status
  },
  startCalculatingRoute: async (zoneId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainCleaningZoneStartCalculationCreate(zoneId)
  },
  startCalculatingTransferRoute: async (transferRouteId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainTransferRouteStartCalculationCreate(transferRouteId)
  },
  startCalculatingMissionPointRoute: async (transferRouteId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainMissionPointTransferRouteStartCalculationCreate(transferRouteId)
  },
  patchTransferRoute: async (id: number, geometry?: LineString): Promise<void> => {
    await MainService.mainTransferRoutePartialUpdate(id, {
      user_geometry: geometry,
    })
  },
  deleteTransferRoute: async (id: number): Promise<void> => {
    await MainService.mainTransferRouteDestroy(id)
  },
  patchMissionPointTransferRoute: async (id: number, geometry?: LineString): Promise<void> => {
    await MainService.mainMissionPointTransferRoutePartialUpdate(id, {
      user_geometry: geometry,
    })
  },
  deleteMissionPointTransferRoute: async (id: number): Promise<void> => {
    await MainService.mainMissionPointTransferRouteDestroy(id)
  },
  createLayer: async (missionId: number, name: string): Promise<IGeoTiffLayer> => {
    const layer = await MainService.mainMissionXyzLayerCreate({ name, mission_id: missionId })
    return { id: layer.id, urlTiles: undefined, name, status: GEOTIFF_LAYER_STATUSES.NEW }
  },
  uploadLayerFile: async (layerId: number, file: File): Promise<void> => {
    await MainService.mainMissionXyzLayerUploadFileCreate(layerId, { file })
  },
  deleteLayer: async (layerId: number): Promise<void> => {
    await MainService.mainMissionXyzLayerDestroy(layerId)
  },
  async fetchGeotiffFiles(): Promise<FileData[]> { 
    try {
        const response = await MainService.mainListGeoTiffFiles();
        return response; 
    } catch (error) {
        console.error("Error fetching GeoTIFF files:", error);
        throw error; 
    }
  },

  async fetchRouteRecordedFiles(): Promise<FileData[]> { 
    try {
        const response = await MainService.mainListRecordedRoutesFiles();
        return response; 
    } catch (error) {
        console.error("Error fetching recorded routes files:", error);
        throw error; 
    }
  },

  async fetchRouteRecordedZones(): Promise<{zones: ICleaningZone[], routes: IRoute[]}> { 
    try {
        const response = await MainService.mainListRecordedRoutesWithZones();
        return {zones: response.map(zoneRaw => normalizeCleaningZone(zoneRaw)), 
                routes: response.map(zoneRaw => normalizeCleaningRoute(zoneRaw))}; 
    } catch (error) {
        console.error("Error fetching recorded routes and zones:", error);
        throw error; 
    }
  },

  async deleteRouteRecordedZone(filename: string): Promise<void> { 
    try {
        const response = await MainService.deleteRecordedRouteZone({
          filename
        });
        return;
    } catch (error) {
        console.error("Error fetching recorded routes and zones:", error);
        throw error; 
    }
  },

  async renameRouteRecordedZone(filename: string, new_filename: string): Promise<void> { 
    try {
        const response = await MainService.renameRecordedRouteZone({
          filename,
          new_filename
        });
        return;
    } catch (error) {
        console.error("Error fetching recorded routes and zones:", error);
        throw error; 
    }
  },

  uploadLayerFileByName: async (layerId: number, filename: string): Promise<void> => {
    await MainService.mainMissionXyzLayerUploadFileByName(
        layerId, 
        { filename }
    );
  },
  
}
