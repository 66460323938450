import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { StationActions } from 'store/actions/station'
import { selectLoadingProgress } from 'store/selectors/station'
import store from 'store/store'
import { stationThunks } from 'store/thunks/station'
import { PROGRESS_TYPES } from 'utils/constants'
import StationView from './StationView/StationView'

import style from './Station.module.css'

const Station = () => {
  const loadingProgress = useSelector(selectLoadingProgress)
  const params = useParams()
  const stationId = parseFloat(params.id || '')
  const dispatch = useDispatch()

  useEffectOnce(() => {
    store.dispatch(stationThunks.fetchStation(stationId))
    return () => {
      dispatch(StationActions.resetStation())
    }
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: <StationView />,
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <span>Error</span>,
  }

  return <div className={style.container}>{stateList[loadingProgress]}</div>
}

export default Station
