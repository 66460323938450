import clsx from 'clsx'
import Box from 'components/Box/Box'
import { useTranslation } from 'react-i18next'
import { IMissionInfo } from 'utils/interfaces'
import { MissionJobRead } from 'api/schema'

import MissionItem from './MissionItem/MissionItem'

import style from './MissionTable.module.css'

const MissionTable = ({ missions, tasks, onMissionsUpdated }: { missions: IMissionInfo[]; tasks: MissionJobRead[]; onMissionsUpdated: () => void }) => {
  const { t } = useTranslation()

  return (
    <Box className={style.box}>
      <div className={style.tableWrapper}>
        <div className={style.tableHeader}>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('missions.id')}</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('missions.name')}</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>Robot type</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('missions.tasks')}</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('missions.status')}</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('missions.createdAt')}</div>
        </div>
        {missions.length ? (
          <div className={style.table}>
            {missions.map(mission => (
              <MissionItem key={mission.id} mission={mission} tasks={tasks} onMissionsDeleted={onMissionsUpdated} />
            ))}
          </div>
        ) : (
          <div className={style.emptyWrapper}>
            <div className={style.emptyTitle}>{t('missions.no_missions_title')}</div>
            <div className={style.emptyDescription}>{t('missions.no_missions_description')}</div>
          </div>
        )}
      </div>
    </Box>
  )
}

export default MissionTable
