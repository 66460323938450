import StationImage from '../StationImage/StationImage'
import { useSelector } from 'react-redux'
import { selectStation } from 'store/selectors/station'
import { IRobotInfo } from 'utils/interfaces'
import style from './StationDetails.module.css'
import StationInfo from './StationInfo/StationInfo'

interface StationDetailsProps {
  robots: IRobotInfo[];
}

const StationDetails: React.FC<StationDetailsProps> = ({ robots }) => {
  const station = useSelector(selectStation)

  return (
    <div className={style.root}>
      <StationImage />
      <div className={style.tableWrapper}>
        <StationInfo robots= {robots} />
      </div>
    </div>
  )
}

export default StationDetails
