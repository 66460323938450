import { Feature, Polygon } from 'geojson'
import RobotOnMapImage from 'images/robot_on_map.png'
import { useEffect, useState } from 'react'
import { FillLayer, Layer, Source, SymbolLayer } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { RobotActions } from 'store/actions/robot'
import { selectHighlightedFeature } from 'store/selectors/mission'
import { selectRobot } from 'store/selectors/robot'
import turf from 'turf'
import WebsocketManager, { SOCKET_MESSAGES } from 'utils/WebsocketManager'
import { ROBOT_NETWORK_STATUSES, TRAJECTORY_LINE_WIDTH_IN_METERS } from 'utils/constants'
import { getYawFromQuaternion } from 'utils/getYawFromQuaternion'
import { IRobotOnMap } from 'utils/interfaces'
import { centerMapTo, zoomMapTo } from 'utils/mapUtils'

import CenterOnRobotButton from './CenterOnRobotButton/CenterOnRobotButton'

const layerRobotStyle: SymbolLayer = {
  id: 'robot-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'robot',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.6, 22, 3.2],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 1,
    'icon-opacity-transition': { delay: 0, duration: 0 },
  },
}

const trajectoryStyle: FillLayer = {
  id: 'trajectory-style',
  type: 'fill',
  paint: {
    'fill-color': '#9747FF',
    'fill-opacity': 0.7,
  },
}

const RobotOnMap = ({ map }: { map: mapboxgl.Map | undefined }) => {
  const robot = useSelector(selectRobot)
  const dispatch = useDispatch()

  const [robotStyle, setRobotStyle] = useState<SymbolLayer>(layerRobotStyle)
  const [robotOnMap, setRobotOnMap] = useState<IRobotOnMap>()
  const [robotZoomed, setRobotZoomed] = useState(false)
  const [robotCenteringMode, setRobotCenteringMode] = useState(true)
  const [trajectoryPolygon, setTrajectoryPolygon] = useState<Feature<Polygon> | undefined>()
  const highlightedFeature = useSelector(selectHighlightedFeature)

  useEffect(() => {
    if (!robotZoomed && robotOnMap) {
      // зумим на робота, если телеметрия пришла в первый раз
      zoomMapTo(map, robotOnMap ? [robotOnMap] : [])
      setRobotZoomed(true)
    }
    if (robotCenteringMode) {
      centerMapTo(map, robotOnMap ? [robotOnMap] : [])
    }
  }, [robotZoomed, robotOnMap, map, robotCenteringMode])

  useEffect(() => {
    if (highlightedFeature) {
      setRobotCenteringMode(false)
    }
  }, [highlightedFeature])

  useEffect(() => {
    loadRobotImage(map)
    map?.on('style.load', () => loadRobotImage(map))
    map?.on('dragstart', () => setRobotCenteringMode(false))
  }, [map])

  const loadRobotImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('robot')) {
      return
    }
    map?.loadImage(RobotOnMapImage, (error, image) => {
      if (error || !image) {
        return
      }
      map?.addImage('robot', image)
    })
  }

  useEffect(() => {
    // let websocketMessagesCount = 0
    const callback = (data: any) => {
      if (robot.robotInfo?.serialNumber !== data.robot_id) {
        return
      }
      const position = data.odometry.pose.pose.position
      const yaw = getYawFromQuaternion(data.odometry.pose.pose.orientation)
      setRobotOnMap({
        id: 'robot_on_map',
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.x, position.y],
        },
        properties: {
          yaw,
        },
      })

      setRobotStyle({
        ...robotStyle,
        layout: {
          ...robotStyle.layout,
          'icon-rotate': yaw,
        },
        paint: {
          ...robotStyle.paint,
        },
      })

      // websocketMessagesCount += 1
      // if (websocketMessagesCount % 3 === 0) {
      dispatch(
        RobotActions.setTelemetry({
          timestamp: data.header.stamp.sec,
          batteryPower: data.battery_power,
          waterTankFilling: data.water_level,
          dirtyWaterTankFilling: data.dirty_water_level,
          temperatureControlUnit: data.temperature_control_unit,
          temperatureChassis: data.temperature_chassis,
          temperatureLidar: data.temperature_lidar,
          temperatureExternal: data.temperature_external,
          trashFullness: data.garbage_level,
          locks: {
            body: data.body_lock,
            // front: data.front_lock,
            // charge: data.charge_lock,
            // trash: data.trash_lock,
            watering: data.watering,
            light: data.front_light,
            danger_button: data.danger_button,
          },
          cleaningEquipmentState: data.equipment_state,
          rtkMode: data.rtk_mode,
          statusCode: data.status_code,
          quaternion: data.odometry.pose.pose.orientation,
          velocity: data.odometry.twist.twist.linear.x,
        })
      )
      dispatch(RobotActions.pushPositionToTrajectory([position.x, position.y]))
      // }
    }
    WebsocketManager.sendMessage(
      '{"type": "robot.subscribe", "robot_serial_number": ' + robot.robotInfo?.serialNumber + '}'
    )
    WebsocketManager.addEventListener(SOCKET_MESSAGES.ROBOT_TELEMETRY, callback)
    return () => {
      WebsocketManager.removeEventListener(SOCKET_MESSAGES.ROBOT_TELEMETRY, callback)
      WebsocketManager.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try {
      setTrajectoryPolygon(turf.buffer(robot.trajectory, TRAJECTORY_LINE_WIDTH_IN_METERS, 'meters'))
    } catch (error) {}
  }, [robot.trajectory])

  useEffect(() => {
    if (robot.robotInfo?.networkStatus === ROBOT_NETWORK_STATUSES.OFFLINE) {
      setRobotStyle({
        ...layerRobotStyle,
        paint: {
          ...layerRobotStyle.paint,
          'icon-opacity': 0.6,
        },
      })
    }
  }, [robot.robotInfo?.networkStatus])

  return (
    <>
      {trajectoryPolygon?.geometry.coordinates.length && robotOnMap && (
        <Source id="cleaning-route" type="geojson" data={trajectoryPolygon}>
          <Layer {...trajectoryStyle} />
        </Source>
      )}
      {robotOnMap && (
        <Source id="robot" type="geojson" data={robotOnMap}>
          <Layer {...robotStyle} />
        </Source>
      )}
      <CenterOnRobotButton onClick={() => setRobotCenteringMode(true)} />
    </>
  )
}

export default RobotOnMap
