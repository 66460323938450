import { RobotApi } from 'api/RobotApi'
import { StationApi } from 'api/StationApi'
import Loader from 'components/Loader/Loader'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import { IStation, IRobotInfo } from 'utils/interfaces'
import { ROBOT_NETWORK_STATUSES } from 'utils/constants'

import RobotListView from './RobotListView/RobotListView'

const RobotList = () => {
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)
  const [robots, setRobots] = useState<IRobotInfo[]>([])
  const [stations, setStations] = useState<IStation[]>([])

  const fetchData = async () => {
    try {
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const robots = await RobotApi.fetchRobots()
      setRobots(robots)
      const stations = await StationApi.fetchStations()
      setStations(stations)
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  const fetchStations = async () => {
    const stations = await StationApi.fetchStations()
    setStations(stations)
  }

  useEffectOnce(() => {
    fetchData()
  })

  const stateList = {
    [`${PROGRESS_TYPES.SUCCESS}`]: (
      <RobotListView 
        robots={robots.sort((a, b) => {
          if (a.networkStatus === b.networkStatus) {
            return a.serialNumber - b.serialNumber;
          }
          return a.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE ? -1 : 1;
        })}  
        stations={stations} 
        onRobotsUpdated={fetchData} 
        onStationsChanged={fetchStations} 
      />
    ),
    [`${PROGRESS_TYPES.WORK}`]: <Loader />,
    [`${PROGRESS_TYPES.ERROR}`]: <div>Error</div>,
  }

  return stateList[loadingProgress]
}

export default RobotList
