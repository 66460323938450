import { configureStore } from '@reduxjs/toolkit'
import { RootState } from 'utils/interfaces'

import missionReducer from './actions/mission'
import missionControlReducer from './actions/missionControl'
import robotReducer from './actions/robot'
import stationReducer from './actions/station'

export default configureStore<RootState>({
  reducer: {
    mission: missionReducer,
    robot: robotReducer,
    missionControl: missionControlReducer,
    station: stationReducer,
  },
})
