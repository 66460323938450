import { Tabs } from '@mui/material'
import Box from 'components/Box/Box'
import Loader from 'components/Loader/Loader'
import { StyledTab } from 'components/StyledTab/StyledTab'
import TabPanel from 'components/TabPanel/TabPanel'
import Title from 'components/Title/Title'
import { useEffectOnce } from 'hooks/useEffectOnce'
import ViewZonesMode from 'pages/Mission/MissionView/Modes/ViewZone/ViewZonesMode'
import RobotControl from 'pages/Robot/RobotView/RobotLeftBlock/RobotControl/RobotControl'
import RobotInfo from 'pages/Robot/RobotView/RobotLeftBlock/RobotDetails/RobotInfo/RobotInfo'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { selectMission } from 'store/selectors/mission'
import { selectLoadingProgress } from 'store/selectors/missionControl'
import { selectRobot } from 'store/selectors/robot'
import store from 'store/store'
import { missionControlThunks } from 'store/thunks/missionControl'
import { robotThunks } from 'store/thunks/robot'
import { PROGRESS_TYPES, TIMEOUT_FETCHING_STATUS } from 'utils/constants'
import { PATH } from 'utils/constants'
import { RobotTypeEnum } from 'api/schema'

import MissionControlMap from './MissionControlMap/MissionControlMap'
import MissionStats from './MissionStats/MissionStats'
import RobotControlButtons from './RobotControlButtons/RobotControlButtons'

import style from './MissionControl.module.css'

const MissionControl = () => {
  const { t } = useTranslation()
  const params = useParams()
  const jobId = parseFloat(params.id || '')
  const mission = useSelector(selectMission)
  const robot = useSelector(selectRobot)
  const [tab, setTab] = useState(0)
  const loadingProgress = useSelector(selectLoadingProgress)
  const hideCleaningType = !mission.missionInfo || 
                           !mission.missionInfo.robot_type || 
                           mission.missionInfo.robot_type != RobotTypeEnum.SWEEPER_SCRUBBER; 

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  useEffectOnce(() => {
    store.dispatch(missionControlThunks.fetchMissionAndRobot(jobId))
  })

  useEffect(() => {
    const interval = setInterval(async () => {
      if (robot.robotInfo) {
        store.dispatch(robotThunks.fetchRobot(robot.robotInfo.id))
      }
    }, TIMEOUT_FETCHING_STATUS)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robot.robotInfo?.id])

  return (
    <div className={style.container}>
      {loadingProgress === PROGRESS_TYPES.WORK ? (
        <Loader />
      ) : (
        <>
          <Title text={mission.missionInfo?.name || ''} />
          <div className={style.main}>
            <div className={style.leftBlock}>
              <Box className={style.box}>
                <Tabs value={tab} onChange={handleChange} indicatorColor="primary" sx={{ height: 52, minHeight: 52 }}>
                  <StyledTab
                    label={t('robot.details')}
                    sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, width: 185 }}
                    disableRipple
                  />
                  <StyledTab
                    label={t('robot.control')}
                    sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, width: 185 }}
                    disableRipple
                  />
                  <StyledTab
                    label={t('mission.details')}
                    sx={{ fontSize: 16, fontWeight: '400', textTransform: 'none', letterSpacing: 0, width: 185 }}
                    disableRipple
                  />
                </Tabs>
                <TabPanel value={tab} index={0} disablePadding={true}>
                  <div>
                    <Link to={`${PATH.ROBOT}${robot.robotInfo?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Title text={robot.robotInfo?.name || ''} />
                    </Link>
                  </div>
                  <RobotControlButtons />
                  <MissionStats robot={robot.robotInfo} />
                  <RobotInfo />
                </TabPanel>
                <TabPanel value={tab} index={1} disablePadding={true}>
                  <RobotControl />
                </TabPanel>
                <TabPanel value={tab} index={2} disablePadding={true}>
                  <ViewZonesMode
                    cleaningZones={mission.cleaningZones}
                    restrictZones={mission.restrictZones}
                    hideActions={true}
                    hideCleaningType={hideCleaningType}
                  />
                </TabPanel>
              </Box>
            </div>
            <div className={style.map}>
              <MissionControlMap />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default MissionControl
