import AddRobotModal from '../AddRobotModal/AddRobotModal'
import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { format } from 'date-fns'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH, ROBOT_NETWORK_STATUSES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IStation, IRobotInfo } from 'utils/interfaces'

import style from './RobotItem.module.css'

const RobotItem = ({
  robot,
  onRobotUpdated,
  stations,
  robots,
  hightlighted,
  onStationsChanged,
}: {
  robot: IRobotInfo
  stations: IStation[]
  robots: IRobotInfo[]
  onRobotUpdated: () => void
  hightlighted: boolean
  onStationsChanged: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToRobot = (robotId: number) => {
    navigate(`${PATH.ROBOT}${robotId}`)
  }
  const [deleteRobotDialog, setDeleteRobotDialog] = useState(false)
  const [editingRobot, setEditingRobot] = useState(false)

  const deleteRobot = async () => {
    try {
      await RobotApi.deleteRobot(robot.id)
      setDeleteRobotDialog(false)
      onRobotUpdated()
    } catch (error) {
      console.log('Error deleting robot:', error)
    }
  }

  return (
    <>
      <div
        className={hightlighted ? clsx(style.tableRow, style.highlighted) : style.tableRow}
        onClick={() => goToRobot(robot.id)}
        key={robot.id}
      >
        <span className={style.tableCol}>{robot.serialNumber}</span>
        <span className={style.tableCol}>{robot.name}</span>
        <span
          className={
            robot.networkStatus === ROBOT_NETWORK_STATUSES.ONLINE ? clsx(style.tableCol, style.online) : style.tableCol
          }
        >
          {t(`robot.${robot.networkStatus}`)}
        </span>
        <span className={style.tableCol}>{robot.job?.missionName}</span>
        <span className={robot.activeMode ? clsx(style.tableCol, style.online) : style.tableCol}>
          {robot.activeMode ? t('robot.online') : t('robot.offline')}
        </span>
        {/* <span className={style.tableCol}>Brazil, Holo st. ...</span> */}
        <span className={style.tableCol}>{format(new Date(robot.updatedAt), 'dd.MM.yyyy HH:mm')}</span>
        <div className={style.zoneItemColSmall}>
          <ActionsMenu>
            <ActionsMenuItem onClick={() => setEditingRobot(true)} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span className={style.menuItemText} data-test-id={getTestId('edit-title')}>
                {t('common.edit')}
              </span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={() => setDeleteRobotDialog(true)} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        </div>
      </div>
      <ConfirmDialog
        title={t('robots.delete_question')}
        isOpen={deleteRobotDialog}
        onClose={() => setDeleteRobotDialog(false)}
        onConfirmClick={deleteRobot}
        confirmText={t('common.delete')}
        danger={true}
      />
      <AddRobotModal
        isOpen={editingRobot}
        stations={stations}
        robots={robots}
        onClose={() => setEditingRobot(false)}
        onSave={onRobotUpdated}
        robotExisting={robot}
        onStationsChanged={onStationsChanged}
      />
    </>
  )
}

export default RobotItem
