import StationOnMapImage from 'images/station_on_map.png'
import { useEffect, useState } from 'react'
import { FillLayer, Layer, Source, SymbolLayer } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { selectHighlightedFeature } from 'store/selectors/mission'
import { selectStation } from 'store/selectors/station'
import { IRobotOnMap } from 'utils/interfaces'
import { centerMapTo, zoomMapTo } from 'utils/mapUtils'

import CenterOnStationButton from './CenterOnStationButton/CenterOnStationButton'

const layerStationStyle: SymbolLayer = {
  id: 'station-image-style',
  type: 'symbol',
  layout: {
    'icon-image': 'station',
    'icon-anchor': 'center',
    'icon-rotation-alignment': 'map',
    'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.6, 22, 3.2],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 1,
    'icon-opacity-transition': { delay: 0, duration: 0 },
  },
}

const StationOnMap = ({ map }: { map: mapboxgl.Map | undefined }) => {
  const station = useSelector(selectStation)
  const dispatch = useDispatch()

  const [stationStyle, setStationStyle] = useState<SymbolLayer>(layerStationStyle)
  const [stationOnMap, setStationOnMap] = useState<IRobotOnMap>()
  const [stationZoomed, setRobotZoomed] = useState(false)
  const [stationCenteringMode, setStationCenteringMode] = useState(true)
  const highlightedFeature = useSelector(selectHighlightedFeature)

  useEffect(() => {
    const yaw = 0;
    setStationOnMap({
      id: 'station_on_map',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [station.point ? station.point.coordinates[0] : 0, station.point ? station.point.coordinates[1] : 0],
      },
      properties: {
        yaw,
      },
    })
  }, [station])

  useEffect(() => {
    if (!stationZoomed && stationOnMap) {
      zoomMapTo(map, stationOnMap ? [stationOnMap] : [])
      setRobotZoomed(true)
    }
    if (stationCenteringMode) {
      centerMapTo(map, stationOnMap ? [stationOnMap] : [])
    }
  }, [stationZoomed, stationOnMap, map, stationCenteringMode])

  useEffect(() => {
    if (highlightedFeature) {
      setStationCenteringMode(false)
    }
  }, [highlightedFeature])

  useEffect(() => {
    loadRobotImage(map)
    map?.on('style.load', () => loadRobotImage(map))
    map?.on('dragstart', () => setStationCenteringMode(false))
  }, [map])

  const loadRobotImage = (map: mapboxgl.Map | undefined) => {
    if (map?.hasImage('station')) {
      return
    }
    map?.loadImage(StationOnMapImage, (error, image) => {
      if (error || !image) {
        return
      }
      map?.addImage('station', image)
    })
  }

  useEffect(() => {
    if (station.online === true) {
      setStationStyle({
        ...layerStationStyle,
        paint: {
          ...layerStationStyle.paint,
          'icon-opacity': 0.6,
        },
      })
    }
  }, [station.online])

  return (
    <>
      {stationOnMap && (
        <Source id="station" type="geojson" data={stationOnMap}>
          <Layer {...stationStyle} />
        </Source>
      )}
      <CenterOnStationButton onClick={() => setStationCenteringMode(true)} />
    </>
  )
}

export default StationOnMap
