import { StationApi } from 'api/StationApi'
import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import Box from 'components/Box/Box'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import StationOnMap from 'components/StationOnMap/StationOnMap'
import Title from 'components/Title/Title'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { StationActions } from 'store/actions/station'
import { selectStation } from 'store/selectors/station'
import { TIMEOUT_FETCHING_STATUS } from 'utils/constants'
import { IMapboxElement, IRobotInfo } from 'utils/interfaces'

import StationLeftBlock from './StationLeftBlock/StationLeftBlock'

import style from './StationView.module.css'
import Robot from 'pages/Robot/Robot'

const StationView = () => {
  const { t } = useTranslation()
  const station = useSelector(selectStation)
  const mapRef = useRef<IMapboxElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(async () => {
      if (station) {
        const newStation = await StationApi.fetchStation(station.id)
        dispatch(StationActions.setStation(newStation))
        const newLots = await StationApi.fetchLots()
        dispatch(StationActions.setLots(newLots))
      }
    }, TIMEOUT_FETCHING_STATUS)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station.id])

  return (
    <>
      <div className={style.titleBlock}>
        <Title text={station.name || ''} />
        <div className={clsx('text-16-300', style.titleId)}>
          SN: {station.station_id}
        </div>
      </div>
      <div className={style.main}>
        <div className={style.leftBlock}>
          <Box className={style.box}>
            <StationLeftBlock />
          </Box>
        </div>
        <div className={style.map}>
          <MapboxCommon ref={mapRef}>
            <StationOnMap 
              map={mapRef.current?.getMap()}
            />
          </MapboxCommon>
        </div>
      </div>
    </>
  )
}
export default StationView
