import React from 'react';
import { MainApi } from 'api/MainApi'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { format } from 'date-fns'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IMissionInfo } from 'utils/interfaces'
import { MissionJobRead, MissionJobTypeEnum } from 'api/schema';

import style from './MissionItem.module.css'

const MissionItem = ({ mission, tasks, onMissionsDeleted }: { mission: IMissionInfo; tasks: MissionJobRead[]; onMissionsDeleted: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteMissionDialog, setDeleteMissionDialog] = useState(false)

  const goToMission = () => {
    navigate(`${PATH.MISSION}${mission.id}`)
  }

  const deleteMission = async () => {
    try {
      await MainApi.deleteMission(mission.id)
      setDeleteMissionDialog(false)
      onMissionsDeleted()
    } catch (error) {
      console.log('Error deleting mission:', error)
    }
  }

  const copyMission = async () => {
    try {
      await MainApi.copyMission(mission.id)
      onMissionsDeleted()
    } catch (error) {
      console.log('Error copying mission:', error)
    }
  }

  const isMissionActive = tasks.some((task: MissionJobRead) => task.mission_id === mission.id && 
                                                               task.job_type !== MissionJobTypeEnum.DELETE_SCENARIO);
  const tasksUsingMission = tasks.filter((task: MissionJobRead) => task.mission_id === mission.id && 
                                                                   task.job_type !== MissionJobTypeEnum.DELETE_SCENARIO);

  return (
    <>
      <div className={style.tableRow} onClick={() => goToMission()}>
        <span className={style.tableCol}>{mission.id}</span>
        <span className={style.tableCol}>{mission.name}</span>
        <span className={style.tableCol}>{mission.robot_type}</span>
        <span className={style.tableCol}>
          {tasksUsingMission.length > 0 ? (
            tasksUsingMission.map((task: MissionJobRead) => task.id).join(', ')
          ) : (
            <span>{t('No tasks')}</span>
          )}
        </span>
        <span className={style.tableCol}>{t('mission_status.' + mission.status)}</span>
        <span className={style.tableCol}>{format(new Date(mission.createdAt), 'dd.MM.yyyy HH:mm')}</span>
        <span className={style.tableCol}>
          <ActionsMenu>
            <ActionsMenuItem onClick={() => goToMission()} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span className={style.menuItemText} data-test-id={getTestId('edit-title')}>
                {t('common.edit')}
              </span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={() => copyMission()} data-test-id={getTestId('copy-zone-list')}>
              <span className={style.menuItemIcon} />
              <span className={style.menuItemText} data-test-id={getTestId('copy-title')}>
                Copy{/* {t('common.copy')} */}
              </span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={() => setDeleteMissionDialog(true)} 
              data-test-id={getTestId('remove-zone-list')}
              disabled={isMissionActive}
            >
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>
                {t('common.delete')}
              </span>
            </ActionsMenuItem>
          </ActionsMenu>
        </span>
      </div>
      <ConfirmDialog
        title={t('missions.delete_question')}
        isOpen={deleteMissionDialog}
        onClose={() => setDeleteMissionDialog(false)}
        onConfirmClick={deleteMission}
        confirmText={t('common.delete')}
        danger={true}
      />
    </>
  )
}

export default MissionItem
