import { StationApi } from 'api/StationApi'
import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { Point } from 'geojson'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { IStation } from 'utils/interfaces'

import StationView from './StationView/StationView'

import style from './AddStationModal.module.css'

interface IAddStationModal {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  onStationsChanged: () => void
  className?: string
  stationExisting?: IStation
  stations: IStation[]
}

enum STATION_OPTIONS {
  NONE = 'none',
  CREATE_STATION = 'select',
}

const defaultStation = {
  id: 1,
  station_id: 0,
  name: 'Maintance Station',
  online: false,
}

const AddStationModal: React.FC<IAddStationModal> = props => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)
  const station = props.stations.find((station: { id: any }) => station.id === props.stationExisting?.id)

  const [selectedStation, setSelectedStation] = useState<IStation | undefined>(station)

  const getID = () => {
    return props.stations ? Math.max(...props.stations.map(s => s.station_id)) + 1 : defaultStation.station_id
  }
  defaultStation.station_id = getID();

  const [tempStation, setTempStation] = useState<IStation>(selectedStation || defaultStation)

  const onSaveClick = async () => {
    setSaving(true)
    try {
      if (!tempStation?.point) {
        throw new Error('Station point should exist')
      }
      if (props.stationExisting) {
        await StationApi.patchStation(props.stationExisting.id, tempStation?.name, tempStation.station_id, tempStation?.point)
      }
      else {
        await StationApi.createStation(tempStation?.name, tempStation.station_id, tempStation?.point)
      }
      props.onSave()
    } catch (error) {
      console.log('Error creating station')
    }
    setSaving(false)
  }

  const onCancelClick = () => {
    setTempStation(props.stationExisting ? props.stationExisting : defaultStation)
    props.onClose()
  }

  const onStationNameChanged = (name: string) => {
    setTempStation({ ...tempStation, name })
  }

  const onStationSNChanged = (station_id: number) => {
    setTempStation({ ...tempStation, station_id })
  }

  const onStationPointChanged = (point: Point) => {
    setTempStation({ ...tempStation, point })
  }

  const isButtonsDisabled = !tempStation?.point || 
                            tempStation.station_id === undefined || 
                            tempStation.station_id === 0 ||
                            tempStation.name === ''

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      className={clsx(style.root, props.className)}
      shouldCloseOnOverlayClick={true}
      style={{ overlay: { zIndex: 100 } }}
      ariaHideApp={false}
    >
      <div className={style.titleBlock}>
        <div className={style.title}>{props.stationExisting ? 'Edit Station' : 'New Station'}</div>
      </div>
      {(
        <StationView
          onNameChanged={onStationNameChanged}
          onStationSNChanged={onStationSNChanged}
          onPointChanged={onStationPointChanged}
          station={tempStation}
        />
      )}

      <div className={style.footer}>
        <Button.Outlined 
          className={style.btn} 
          onClick={onCancelClick}
        >
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={style.btn}
          disabled={saving || isButtonsDisabled}
          loading={saving}
          onClick={onSaveClick}
        >
          {t('common.save')}
        </Button.Standart>
      </div>
    </Modal>
  )
}

export default AddStationModal
